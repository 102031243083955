@import "~jbc-front/styles/core";

:local {

  .modal {
    border: 1px solid borderColors(base);
    border-radius: 4px;
    height: 90%;
    width: 95%;
    max-width: 760px;
    position: relative;
  }
  .overlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1200;
  }
  .header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid borderColors(base);
    padding: 15px;
    height: 60px;
    background-color: backgroundColors(base);
    color: #333;
  }
  .close {
    margin-left: 20px;
    width: 20px;
    height: 20px;
    color: #aaa;
    cursor: pointer;
  }
  .body {
    overflow: auto;
    padding: 20px 20px 0;
    height: calc(100% - 140px);
    background: #f1f1f1;
  }
  .title {
    padding-top: 5px;
    font-size: 15px;
    font-weight: bold;
  }
  .tools {
    text-align: right;
  }
  .mailTitle {
    margin-bottom: 20px;
  }
  .mailHeading {
    margin-bottom: 5px;
    font-size: 13px;
    font-weight: normal;
  }
  .mailPreview {
    padding: 10px;
    border: 1px solid borderColors(base);
    border-radius: 4px;
    background-color: #fff;
  }
  .closeBottom {
    padding-top: 20px;
    height: 80px;
    background-color: #f1f1f1;
    text-align: center;
  }
}

@import "~jbc-front/styles/core";

:local {
  .header {
    border-radius: 4px 4px 0 0;
    border: solid 1px borderColors(base, default);
    border-bottom: none;
    background-color: #e8e8e8;
    padding: 10px;
    overflow: hidden;
  }

  .body {
    border-radius: 0 0 4px 4px;
    border: solid 1px borderColors(base, default);
    border-top: none;

    display: grid;
    grid-gap: 1px;
    grid-auto-flow: row dense;
    grid-template-columns: repeat(auto-fill, minmax(unquote("min(360px, 100%)"), 1fr));

    & dl {
      outline: 1px solid borderColors(base, default);
    }

    & dd, & dt {
      border: 0;
    }

    @media #{$smartphone} {
      grid-template-columns: 100%;
    }
  }
}

@import "~jbc-front/styles/core";

:local {
  .main {
    margin-bottom: 20px;
    border-radius: 4px;
    padding: 30px;
    background-color: #fff;
  }
  .section {
    margin-top: 20px;
    border-top: 1px solid borderColors(base);
    padding-top: 30px;

    &:first-child {
      margin-top: 0;
      border-top: none;
      padding-top: 0;
    }
  }
  .category {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: bold;
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    li {
      width: 420px;
    }
  }
  .itemWrap {
    display: block;
    height: 100%;
    padding: 20px 10px;
  }
  a.itemWrap {
    &:hover {
      background-color: backgroundColors(hover);
    }
  }
  .itemDisabled {
    composes: itemWrap;
    cursor: default;
    .definition {
      dt {
        color: colors(gray);
      }
    }
    position: relative;
  }

  .definition {
    dt {
      color: colors(blue);
      font-size: 16px;
      font-weight: bold;
    }
    dd {
      margin-top: 5px;
    }
  }
  .icon {
    display: inline-block;
    margin-right: 5px;
    color: #000;
    transform: rotate(-90deg);
  }
  .subText {
    display: block;
    color: textColors(sub);
  }

  @media #{$smartphone} {

    .main {
      padding: 15px;
    }
    .section {
      margin-top: 15px;
      padding-top: 20px;
    }
    .category {
      font-size: 16px;
    }
    .list {
      display: block;

      li {
        width: 100%;

        a {
          padding: 10px 5px;
        }
      }
    }
    .definition {
      dt {
        font-size: 15px;
      }
    }

  }
}

@import '~jbc-front/styles/core';

:local {
  .wrap {
    position: relative;
    margin-bottom: 30px;
  }
  .errorWrap {
    composes: wrap;
    background-color: backgroundColors(alert);
    color: colors(red);
  }
  .inner {
    margin: 0 auto;
    padding: 25px;
    width: 960px;
  }
  .dates {
    display: flex;
    margin-top: 10px;

    dl {
      display: flex;
      margin-left: 20px;

      &:first-child {
        margin-left: 0;
      }
    }
  }
  .title {
    font-weight: bold;
  }

  .error {
    margin-top: 10px;
  }
  .list {
    margin-top: 10px;
    list-style-type: disc;
    list-style-position: inside;
  }
  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
  .waiting {
    display: flex;
    align-items: center;
  }
  .loading {
    :global(.sk-circle) {
      margin: 0 auto;
    }
    :global(.sk-circle .sk-child:before) {
      background-color: colors(blue);
    }
  }
  .info {
    margin-left: 30px;
  }

  @media #{$smartphone} {
    .wrap {
      margin: 0 -15px;
    }
    .inner {
      padding: 15px 30px 15px 15px;
      width: 100%;
    }
    .dates {
      display: block;

      dl {
        display: flex;
        margin-left: 0;
      }
    }
    .close {
      top: 12px;
      right: 10px;
    }
    .info {
      margin-left: 20px;
    }
  }
}

@import '~jbc-front/styles/core';

:local {
  .sectionWrap {
    > div,
    section {
      &:not(:first-child) {
        margin: 40px 0 0 0;
      }
    }

    font-size: 13px;
  }
  .labelContainer {
    display: flex;
    gap: 10px;
    margin-bottom: 8px;

    .icon {
      width: 20px;
      height: 20px;
      color: textColors(sub, default);
      cursor: pointer;
    }
  }

  .alert {
    color: colors(red);
  }

  .summaryFormerJob {
    display: flex;
    margin-bottom: 20px;

    .formerJobLabel {
      min-width: 40px;
      display: flex;
      align-items: center;
    }
    .formerJobContent {
      background-color: #f4f4f4;
      padding: 8px 10px;
      word-break: break-all;
    }
  }
}

@import "~jbc-front/styles/core";

:local {
  .floatingButton {
    position: sticky;
    bottom: 20px;
    max-width: 650px;
    width: 100%;
    margin: 0 auto;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }
}


@import "~jbc-front/styles/core";

:local {
  .section {
    border: solid 1px borderColors(base, default);
    border-radius: 4px;
    width: 100%;
  }

  .header {
    background-color: backgroundColors(base);
    border-bottom: solid 1px borderColors(base, default);
    font-size: 16px;
    font-weight: bold;
    padding: 15px;
    width: 100%;
    border-radius: 4px 4px 0px 0px;
    line-height: 1;
  }

  .body {
    background-color: white;
    padding: 20px;
    border-radius: 0px 0px 4px 4px;

    @media #{$smartphone} {
      padding: 15px;
    }
  }

  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid borderColors(base);
    border-radius: 4px 4px 0 0;
    padding: 14px 20px 13px;
    background: backgroundColors(base);
    font-size: 20px;

    @media #{$smartphone} {
      padding: 10px;
      font-size: 16px;
    }
  }

  .lead {
    margin: 50px 0 50px;
    color: colors(blue, active);
    font-size: 24px;
    font-weight: bold;
    text-align: center;

    @media #{$smartphone} {
      margin: 20px 0;
      font-size: 18px;
    }
  }

  .description {
    margin: 30px 0;
    font-size: 14px;
    text-align: left;

    p {
      margin: 10px 0;
    }

    @media #{$smartphone} {
      margin: 15px 0;

      p {
        margin: 5px 0;
      }
    }
  }

  .notes {
    margin: 30px 0;
    padding: 20px;
    background-color: #e1e1e1;
    text-align: left;

    @media #{$smartphone} {
      padding: 10px;
      margin: 20px 0;
    }
  }

  .leadIcon {
    padding-right: 5px;
    color: textColors(sub);
    vertical-align: text-bottom;
    white-space: nowrap;
  }

  .inner {
    padding: 30px;

    @media #{$smartphone} {
      padding: 15px;
      form {
        .inner {
          padding: 0;
        }
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 100px 0 50px;

    > div {
      margin: 0 10px;
    }
    @media #{$smartphone} {
      display: flex;
      flex-wrap: wrap;
      margin: 30px 0 20px;

      > div + div {
        margin: 10px 0 0;
      }
    }
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #666;
    border-radius: 3px;
    width: 200px;
    height: 100px;
    color: #666;
    font-size: 16px;
    font-weight: bold;

    @media #{$smartphone} {
      width: auto;
    }
  }

  .label {
    margin: 15px 0;
    font-size: 15px;
    font-weight: bold;
  }

  .text {
    margin: 10px 0;
    font-size: 14px;
  }

  .toggles {
    display: inline-flex;
    margin: 40px 0;
    border-radius: 4px;

    div {
      &:first-child {
        border-radius: 4px 0 0 4px;
      }
      &:last-child {
        border-radius: 0 4px 4px 0;
      }
    }

    @media #{$smartphone} {
      display: flex;
    }
  }

  .toggle {
    border: 1px solid #aaa;
    width: 200px;
    padding: 10px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    text-align: center;

    &:not(:first-child) {
      border-left: none;
    }

    @media #{$smartphone} {
      width: 50%;
    }
  }

  .toggleOn {
    composes: toggle;
    border: 1px solid #3498db;
    background-color: #3498db;
    color: #fff;
  }

  .toggleOff {
    composes: toggle;
    color: #aaa;
  }

  .toggleDisabled {
    cursor: not-allowed;
  }

  .line {
    margin: 20px 0 40px;
    border-top: 1px solid borderColors(sub);
  }
}

@import '~jbc-front/styles/core';

:local {
  .warning {
    color: colors(red);
  }
  .importantWarning {
    composes: warning;
  }
}

@import "~jbc-front/styles/core";

:local {
  .formerJobs {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .createAmountHeader {
    justify-content: space-between;
  }

  .updateAmountHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

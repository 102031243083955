@import "~jbc-front/styles/core";

:local {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media #{$smartphone} {
      flex-wrap: wrap;

      > div {
        margin-left: auto;
      }
    }
  }
  .setCityRcd {
    margin-right: auto;
  }
}

@import "~jbc-front/styles/core";

:local {
  .postcode {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .firstCode {
    width: 65px;
  }

  .lastCode {
    width: 80px;
  }
}

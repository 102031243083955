@import "~jbc-front/styles/core";

:local {

  .wrap {
    position: fixed;
    right: 50px;
    bottom: 150px;
    z-index: 1000;
    &:hover .message {
      display: block;
    }
  }

  .circle {
    composes: sk-circle from global;
    margin: 0;
    width: 80px;
    height: 80px;
    text-align: center;
    vertical-align: middle;
    display: table-cell;
    :global(.sk-child:before) {
      background-color: colors(blue);
    }
    color: colors(blue);
  }

  .circleDone {
    composes: circle;
    :global(.sk-child:before) {
      animation-play-state: paused;
    }
  }
  .circleError {
    composes: circleDone;
    color: colors(red);
  }

  .circleDone + .message {
    display: block;
  }

  .message {
    padding: 15px 20px 10px 10px;
    border: solid 1px colors(blue);
    position: absolute;
    width: 300px;
    right: 0;
    bottom: 100px;
    background: #fff;
    word-break: break-all;
    display: none;

    &:before {
      content: "";
      width: 20px;
      height: 20px;
      display: block;
      position: absolute;
      right: 30px;
      bottom: -11px;
      transform: rotate(45deg);
      border-right: solid 1px colors(blue);
      border-bottom: solid 1px colors(blue);
      background: #fff;
    }
  }
  .close {
    position: absolute;
    top: 5px;
    right: 5px;
  }
}



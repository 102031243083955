@import "~jbc-front/styles/core";

:local {
  .note {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: #e1e1e1;
    text-align: left;
    padding: 20px;
  }
}

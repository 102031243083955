@import '~jbc-front/styles/core';

:local {
  .loading {
    width: 120px;
    height: 120px;
    outline: 0;
    background: #fff;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    margin: 20px auto;
  }
}

@import "~jbc-front/styles/core";

:local {
  .preview {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .incomeDetailHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .incomeIcon {
    &:hover {
      cursor: pointer;
    }
  }
  .alert {
    color: colors(red);
    background-color: white;
  }
}

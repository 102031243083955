@import '~jbc-front/styles/core';

:local {
  .input input {
    border: solid 1px #ccc;
    border-radius: 3px;
    box-shadow: none;
    padding: 8px 10px;
    width: 100%;
    background: #fff;
    color: #333;
    height: 38px;
    font-size: 14px;
  }

  .required {
    margin-left: 5px;
    border: 1px solid colors(red);
    border-radius: 2px;
    padding: 1px;
    color: colors(red);
    font-size: 10px;
  }
  .more {
    margin-top: 5px;
    background-color: transparent;
    border: none;
    color: #3498db;
    cursor: pointer;
    outline: none;
  }

  .wrap {
    margin: 0 auto;
    width: 600px;
  }
  .head {
    padding: 8px 0;
    border-radius: 4px 4px 0 0;
    background-color: #a4a4a4;
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
  }
  .body {
    padding: 40px 45px;
    border: 1px solid #a4a4a4;
    border-radius: 0 0 4px 4px;
  }
  .label {
    display: flex;
    margin: 0 0 40px;
    font-size: 14px;
    font-weight: bold;
  }
  .number {
    display: block;
    border-radius: 2px;
    width: 24px;
    height: 24px;
    background-color: #9f9f9f;
    color: #fff;
    line-height: 24px;
    text-align: center;
  }
  .detail {
    margin-left: 15px;
    padding-top: 3px;
    width: 400px;
  }
  .text {
    margin-bottom: 20px;
  }
  .description {
    margin-top: 85px;
  }
  .warning {
    margin: 10px 0;
    font-weight: normal;
  }
  .warningTitle {
    display: block;
    font-weight: bold;
  }
  .warningList {
    > li {
      margin: 8px 0;
      font-weight: normal;
    }
  }
  .requireList {
    font-weight: normal;
  }
  .requiredText {
    color: colors(red);
  }
  .helpWrap {
    position: relative;
    display: inline-block;
    margin: 0 5px;
    cursor: pointer;
  }

  .itemNumber {
    margin-right: 5px;
  }

  @media #{$smartphone} {
    .wrap {
      margin: 20px auto 0;
      width: 100%;
    }
    .body {
      padding: 20px;
    }
    .detail {
      width: 100%;
    }
    .description {
      margin-top: 40px;
    }
    .spTableWidth {
      width: 500px;
    }
  }
}

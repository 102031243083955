@import '~jbc-front/styles/core';

:local {
  .mainSearchFields {
    display: flex;
    justify-content: space-between;

    @media #{$smartphone} {
      display: block;
    }
  }
  .searchForm {
    composes: searchForm from 'jbc-front/components/SearchForm.scss';
    > form > div > div {
      &:nth-child(2) {
        div {
          input {
            width: 175px !important;
          }
        }
      }
    }
    @media #{$smartphone} {
      > form > div > div {
        &:first-child {
          width: 100%;
        }
        &:nth-child(2) {
          div {
            input {
              width: 230px !important;
            }
          }
        }
      }
    }
  }
  .sortingFieldsContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px
  }
}

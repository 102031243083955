@import "~jbc-front/styles/core";

:local {
  .hint {
    transform: translateY(1px);

    > div:last-child {
      z-index: 999;

      > div {
        left: 100%;
        transform: translate(20px, 100%);
      }
    }

    > div::after {
      transform: translate(30px, 23px) rotate(45deg);
    }
  }

  .fullName {
    max-width: 400px;
  }

  .radiusTh {
    th {
      &:first-child {
        border-radius: 4px 0 0 0;
      }

      &:last-child {
        border-radius: 0 4px 0 0;
      }
    }
  }

  .text {
    line-height: 1.4em;
    font-weight: initial;

    .indent {
      padding-left: 1.2em;
    }
  }
}

:local {
  .cardSection {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin: 0 auto 30px;
    max-width: 750px;
    padding: 60px;
  }
  .destinationLabelWrap {
    display: flex;
    justify-content: space-between;
    margin: 0 0 72px 0;
  }
  .destinationLabel {
    width: 40%;
  }
  .previewWrap {
    width: 60%;
  }
  .previewLabel {
    margin: 0 0 8px 0;
  }
  .preview {
    border: 2px solid #ddd;
    border-radius: 3px;
    padding: 14px 20px;
    div {
      margin: 0px;
      textarea {
        resize: none;
      }
    }
    .previewAddress {
      margin: 0 0 10px 0;
    }
  }
  .cityReportSummary {
    border: 2px solid #ddd;
    border-radius: 3px;
    margin: 0 0 60px 0;
    padding: 30px;
  }
  .cityReportSummaryTitle {
    font-size: 20px;
    font-weight: normal;
    margin: 0 0 21px 0;
    text-align: center;
  }
  .form {
    > div {
      &:last-child {
        margin: 0px;
      }
    }
  }
}

@import "~jbc-front/styles/core";

:local {
  .postit {
    display: inline-block;
    background-size: cover;
    box-shadow: 0 3px 10px 0 #ddd;
    border-radius: 50%;
    margin: auto;
    composes: u-cur-pointer from global;
    color: colors(blue);
  }

  .commonMemoStyles {
    width: auto;
    background-color: #fff;
    box-shadow: 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 11px 15px -7px rgba(0, 0, 0, 0.20);
    border-radius: 4px;
    overflow: hidden;
    z-index: 500;
  }

  .memoContentWrap {
      composes: commonMemoStyles;
      position: absolute;
      right: 10px;
      width: calc(100% - 20px);

  }

  .memoRndContent {
    composes: commonMemoStyles;
    cursor: move;
    &.oneMemos {
      .list {
        gap: 0;
      }
    }
  }

  .content {
    padding: 20px;
    height: auto;
  }

  .body {
    margin: 5px 0;
    white-space: pre-wrap;
    font-size: 14px;
  }

  .footer {
    text-align: center;
    width: 100%;
    margin-top: 20px;

    button {
      margin: 10px 20px 16px 20px;
    }
  }

  .title {
    background-color: colors(blue);
    height: 40px;
    font-weight: bold;
    font-size: 13px;
    padding: 13.5px 10px 13.5px 20px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .comments {
    display: inline-block;
  }
  .list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 20px;
    overflow-y: auto;
    max-height: calc(100vh - 300px);
    text-align: left;
    @media #{$smartphone} {
      grid-template-columns: 1fr;
      max-height: calc(100vh - 400px);
    }
  }
  .comment {
    box-shadow: 0 1px 0 0 #eee;
    p {
      margin: 20px;
      padding-bottom: 20px;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.5;
      color: textColors(base);
    }
  }
  .user {
    font-size: 14px;
  }
  .header {
    margin: 20px;
    time {
      font-size: 12px;
      font-weight: 500;
      color: textColors(sub);
    }
    &:after {
      content: "";
      display: block;
      clear: both;
    }
  }
  .tools {
    font-size: 14px;
    display: flex;
  }
  .tool {
    color: textColors(sub);

    + .tool {
      margin-left: 5px;
    }

    &:hover {
      color: textColors(sub);
    }

    composes: u-cur-pointer from global;
  }

  .row {
    display: flex;
    flex-direction: column;
    padding: 5px;
    border-radius: 4px;
    flex-basis: 280px;
    min-width: 280px;
    &.oneMemo {
      background-color: red;
    }
  }

  .detail {
    padding: 10px;
    height: 100%;
    text-align: center;
  }

  .memoHead {
    display: flex;
    align-items: center;
    .user {
      font-size: 13px;
    }
    .action {
      min-width: 50px;
      margin-left: auto;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 3px;
    }
  }

  .memoBody {
    margin: 5px 0;
    white-space: pre-wrap;
    font-size: 13px;
  }

  .dateWrap {
    :global(.rdtPicker) {
      bottom: 38px;
    }
  }

  .memoFooter {
    margin-top: auto;
    text-align: right;
    .info {
      font-size: 12px;
      color: #666;
    }
  }

  @each $key, $type in $memoColors {
    .memo_#{$key} {
      color: map-get($type, default)
    }
  }
  @each $key, $type in $memoColors {
    .memo_border_BG_default_#{$key} {
      border: 1px solid map-get($type, default);
      background-color: map-get($type, light)
    }
  }
  @each $key, $type in $memoColors {
    .memo_bg_light_#{$key} {
      background-color: map-get($type, light)
    }
  }
  .LoadingPageWrap {
    position: absolute;
  }
}

:local {
  .loading {
    composes: loading from '../city_report/Report.scss'
  }

  .loadingButton {
    composes: loadingButton from '../city_report/Report.scss'
  }

  .file {
    border: 1px solid #000;
    border-radius: 4px;
    padding: 7px;
    margin: 10px 0;
  }
}

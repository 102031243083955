@import "~jbc-front/styles/core";

:local {
  .submittedCities {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto 30px;
    width: 70%;
    > p {
      align-items: center;
      display: flex;
    }
    .rate {
      font-size: 22px;
      margin: 0 0 0 16px;
    }
  }
  .progress {
    margin: 0 0 30px 0;
  }
  .buttonArea {
    display: flex;
    justify-content: flex-end;
    margin: 0 0 36px 0;
  }
  .buttonControl {
    margin: 0 8px 0 0;
  }
  .actionButton {
    width: 180px;
    height: 40px;
    font-size: 14px;
    font-weight: 400;
    background: #3498db;
  }
  .cityReportTable {
    tbody {
      > tr {
        cursor: pointer;
        &:hover {
          background: #ededed;
        }
      }
    }
    td {
      position: relative;
    }
  }
  .finished {
    td {
      color: #979797;
      &:not(:first-child) {
        &:before {
          border-bottom: 1px solid #979797;
          content: '';
          left: 0;
          position: absolute;
          top: 50%;
          width: 100%;
        }
      }
      &:nth-child(2) {
        &:before {
          left: 25px;
        }
      }
      &:last-child {
        &:before {
          left: -25px;
        }
      }
    }
  }
  .link {
    color: colors(blue);
    text-decoration: underline;
    &:hover {
      color: colors(blue);
    }
  }
  .paginator {
    margin: 30px 0 0 0;
  }
  .disabled {
    cursor: not-allowed;
    &:after {
      opacity: $baseOpacity;
    }
    &:hover{
      .disabledReason{
        display: block;
      }
    }
  }
  .disabledReason {
    display: none;
    position: absolute;
    top: 0;
    border-radius: 3px;
    white-space: nowrap;
    color: white;
    font-size: 12px;
    z-index: 99;
    .content {
      background-color: #666;
      margin-top: 40px;
      padding: 10px;
      border-radius: 3px;
      line-height: 1.5;
    }
  }
}

@import "~jbc-front/styles/core";

:local {
  .spouse {
    composes: box from 'jbc-front/components/Form.scss';
    > div > div + div {
      padding-top: 20px;
      border-top: 1px solid #ddd;
    }
  }
  .OtherSalaryIncome {
    & .close + div{
      margin-bottom: 60px;
    }
    & .open + div{
      margin-bottom: 30px;
    }
  }
}
@import "~jbc-front/styles/core";

:local {
  .button {
    margin-left: 10px;
    box-shadow: none;
    padding: 0 12px;
    height: 28px;
    font-size: 13px;
    font-weight: bold;
    line-height: 28px;
    > div {
      right: -100px;
    }
  }
  .buttonEdit {
    composes: button;
  }

  .buttonIcon {
    margin-right: 5px;
  }
}

@import '~jbc-front/styles/core';

:local {
  .noticeWrap {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto 30px;
    width: 90%;
  }
  .descriptionWrap {
    background: #ffffff;
    box-shadow: 0 1px 0 0 #e0e0e0;
    display: flex;
    justify-content: space-between;
    margin: 0 0 35px 0;
    padding: 13px 13px 13px 27px;
  }
  .descriptionOpen {
    flex-direction: column;
    padding: 25px;
    > .pulldownWrap {
      justify-content: flex-end;
    }
  }
  .sectionHeadingWrap {
    align-items: center;
    display: flex;
  }
  .headingNumber {
    align-items: center;
    background-color: #9f9f9f;
    border-radius: 2px;
    display: flex;
    height: 24.7px;
    justify-content: center;
    margin: 0 12px 0 0;
    width: 24px;
    > p {
      color: #ffffff;
      font-family: YuGo;
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
      letter-spacing: normal;
    }
  }
  .headingText {
    font-weight: normal;
  }
  .description {
    font-size: 13px;
    padding: 18px 0 18px 35px;
    > li {
      display: flex;
    }
  }
  .listNumber {
    margin: 0 10px 0 0;
  }
  .pulldownWrap {
    display: flex;
  }
  .pulldownButton {
    align-items: center;
    border: none;
    cursor: pointer;
    color: #333333;
    display: flex;
    outline: none;
  }
  .pulldown {
    border: 1px solid #ccc;
    border-radius: 2px;
    color: #aaa;
    margin: 0 6px 0 0;
    padding: 3px;
  }
  .pulldownReverse {
    transform: scale(1, -1);
  }
  .generateButtons {
    display: flex;
    justify-content: space-between;
    margin: 0 0 26px 0;
  }
  .csvButtonControl {
    margin: 0 8px 0 auto;
  }
  .actionButton {
    width: 180px;
    height: 40px;
    font-size: 14px;
    font-weight: 400;
    background: #3498db;
  }
  .taxOfficeTable {
    margin: 0 0 30px 0;
  }
  .perPageWrap {
    display: flex;
    justify-content: flex-end;
  }
  .perPageSelect {
    background-color: #ffffff;
    border: solid 1px borderColors(sub);
    border-radius: 2px;
    color: #333333;
    font-size: 13px;
    height: 35px;
  }
  .dropDownMenuWrap {
    align-items: center;
    display: flex;
    cursor: pointer;
    justify-content: center;
  }
  .beSubmitted {
    color: #3484e2;
    margin: 0 5px 0 0;
  }
  .excludedFromSubmit {
    color: #d0021b;
  }
  @media #{$smartphone} {
    .perPageSelect {
      padding: 5px;
    }
  }
}

@import "~jbc-front/styles/core";

:local {

  .name {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px auto 0;
    font-size: 24px;
    font-weight: 500;

    @media #{$smartphone} {
      padding: 0 10px;
      width: 100%;
      text-align: left;
    }
  }

  .buttonArea {
    display: flex;
    justify-content: flex-end;
    margin: 10px auto 0;
    width: 710px;
  }
  .csvButtonControl {
    margin: 0 0 0 0;
  }
  .reportButton {
    min-width: auto;
    width: auto;
    line-height: 35px;
    height: 35px;
    font-size: 14px;
    font-weight: 400;
    background: #fff;
    color: #666;
    border: solid 1px #666;
    border-radius: 0px;
    padding: 0 16px;
  }

  .wrap {
    margin: 10px auto 0;
    width: 710px;
  }

  .note {
    margin-top: 50px;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
  }

  .button {
    margin: 30px auto 0;
    width: 200px;
  }

  .category {
    border-bottom: 1px solid borderColors(table);
    padding: 12px 15px 10px;
    background-color: backgroundColors(base);
    color: #666;
    font-size: 18px;
    font-weight: 500;
  }
  .item {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    background-color: #fff;
    color: #666;
    font-size: 14px;

    dt {
      border-bottom: 1px solid borderColors(table);
      padding: 10px 15px 10px 30px;
      width: 50%;

      &.sublabel {
        width: 100%;
      }
    }
    dd {
      border-bottom: 1px solid borderColors(table);
      padding: 10px 30px 10px 15px;
      width: 50%;
      min-height: 1em;
      text-align: right;
    }
  }
  .arrowIcon {
    margin: 0 8px;
    color: #999;
  }
  .arrowSplit {
    margin: 0 20px;
  }
  .topNavi {
    display: flex;
    justify-content: space-between;
  }
}

@import "~jbc-front/styles/core";

:local {
  .wrap {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    @media #{$smartphone} {
      display: block;
    }
  }
  .main {
    width: 730px;

    @media #{$smartphone} {
      width: 100%;
    }
  }
  .aside {
    width: 280px;

    @media #{$smartphone} {
      border-top: 1px solid borderColors(base);
      padding-top: 30px;
      width: 100%;
    }
  }

  .group {
    margin-bottom: 20px;
  }
  .groupTitle {
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid borderColors(base);
    border-radius: 4px 4px 0 0;
    padding: 13px 10px;
    background: backgroundColors(base);
    font-size: 14px;
    cursor: pointer;
  }
  .groupTitle_opened {
    composes: groupTitle;
  }
  .groupTitle_closed {
    composes: groupTitle;
    border-radius: 4px;
  }
  .step {
    margin-right: 10px;
    border-radius: 3px;
    padding: 5px;
    background-color: colors(blue);
    color: #fff;
    font-size: 13px;
    font-weight: bold;
    white-space: nowrap;
  }
  .pulldown {
    position: absolute;
    top: 50%;
    right: 15px;
    margin-top: -10px;
    color: #333;
    text-indent: 0;
  }
  .is_opened {
    composes: pulldown;
    transform: rotate(180deg);
  }
  .is_closed {
    composes: pulldown;
  }

  .question {
    position: relative;
    border-bottom: 1px solid borderColors(base);
    border-left: 1px solid borderColors(base);
    border-right: 1px solid borderColors(base);
    background-color: #fff;

    > a, > div {
      display: flex;
      align-items: center;
      padding: 12px 15px;
      color: #3498DB;
      font-size: 14px;
    }

  }
  .answered {
    composes: question;

    > a {
      &:hover {
        background-color: backgroundColors(hover);
      }
    }
  }
  .notanswered {
    composes: question;
    border-bottom: 1px solid #fff;
    border-left: 1px solid  #fff;
    border-right: 1px solid  #fff;
    background: #ddd;

    > a, > div {
      color: #b1b1b1;
    }
  }
  .steps {
    margin-right: 10px;
    white-space: nowrap;
  }

  .is_active {
    font-weight: bold;

    a, span {
      color: #333;
    }
    .currentArrow {
      margin-left: auto;
      transform: rotate(-90deg);
      color: #CCCCCC;
    }
  }

  .progress {
    padding: 25px 0 45px;
    font-size: 14px;
    text-align: center;
  }
  .progressCount {
    margin-bottom: 15px;
    font-size: 14px;
  }
  .progressTitle {
    margin-right: 20px;
  }

  .commentBoxList {
    width: 100%;
    margin-bottom: 20px;
    
    > div {
      max-height: 400px;
      overflow: auto;
    }

    .date {
      margin-left: 10px;
      color: #666;
    }
    .comment {
      margin-top: 5px;
      display: block;
      word-break: break-word;
      white-space: pre-line;
    }
    .commentIcon {
      margin-right: 5px;
      color: #999;
    }
  }
  .notice {
    margin-top: 10px;
    font-size: 20px;
    color: red;
  }

}


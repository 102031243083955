@import "~jbc-front/styles/core";

:local {
  .row {
    display: flex;
    align-items: flex-start;
    gap: 20px;
  }

  @media #{$smartphone} {
    .row {
      flex-direction: column;
    }
  }
}

@import "~jbc-front/styles/core";

:local {
  .field {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .label {
    margin-bottom: 8px;
  }

  .note {
    margin-top: 4px;
  }
}

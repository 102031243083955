@import '~jbc-front/styles/core';

:local {
  .noticeWrap {
    text-align: center;
    margin: 0 auto 20px;
  }
  .deliverTable {
    overflow: auto;
  }
  .myNumberSendStatus {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
  }
  .myNumberSendStatusTd {
    padding: 0 3px;
  }
  .registered {
    color: colors(blue);
  }
  .buttonTd {
    height: 30px;
    line-height: 30px;
    margin: 5px 0 0 0;
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .paginator {
    margin-bottom: 60px;
    @media #{$smartphone} {
      margin: auto auto 40px auto;
    }
  }
}

@import "~jbc-front/styles/core";

:local {
  .placeholder {
    margin: 60px 0;
  }
  .dashedBox {
    margin: 0 auto 30px;
    max-width: 600px;
    font-size: 13px;
    border-radius: 6px;
    @include dashed(textColors(sub));

    > div {
      background: transparent;
    }
  }
}

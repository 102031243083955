@import "~jbc-front/styles/core";

:local {
  .form {

    .setting {
      display: grid;
      flex-direction: column;
      gap: 30px;
    }

    @media #{$smartphone} {
      section {
        max-width: 100%;

        > div {
          padding: 15px;
          margin: 0;
        }
      }
    }
  }

  .row {
    display: flex;
    justify-content: space-between;
    gap: 30px;
  }

  .wrap {
    & > section {
      max-width: 100%;
      margin: 0;
    }

    & > a {
      display: block;
      position: relative;
      top: -20px;
      visibility: hidden;
    }

    @media #{$smartphone} {
      margin-bottom: 30px;
    }
  }

  .title {
    margin-bottom: 15px;
    font-size: 15px;
    font-weight: bold;

    @media #{$smartphone} {
      font-size: 14px;
    }
  }

  .listContainer {
    margin-top: 40px;
  }

  .list {
    margin: 30px 0 10px;
    font-size: 15px;
    font-weight: bold;

    @media #{$smartphone} {
      font-size: 14px;
    }
  }

  .select {
    margin-top: 40px;

    @media #{$smartphone} {
      margin-top: 20px;
    }
  }

  .button {
    padding: 20px 0;
    width: 260px;
    height: 125px;
    font-size: 16px;
    line-height: 1.38;

    span {
      font-weight: bold;
    }

    @media #{$smartphone} {
      padding: 10px;
      width: 50%;
      height: auto;
      font-size: 14px;
    }
  }

  .buttonContainer {
    align-items: center;
    display: flex;
    justify-content: center;
    gap: 20px;
    margin: 40px 0 10px;
  }

  .icon {
    margin-bottom: 10px;
  }
  .blue {
    color: colors(blue);
  }
  .red {
    color: colors(red);
  }

  .reminderRow {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: flex-start;
    padding-left: 46px;
  }

  .reminderSelect {
    width: 110px;
  }

  .reminderIcons {
    display: flex;
    gap: 4px;
    cursor: pointer;
  }

}

:local(.dropdown) {
  .dd-menu {
    height: 100%;
    width: 100%;
    .dd-menu-items {
      position: relative;
      margin: 0;
      left: 15px;
      top: 3px;
      ul {
        position: absolute;
        top: 10px;
        right: 0;
        width: 230px;
        background-color: #ffffff;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
        border: solid 1px #e0e0e0;
        color: #333;
        font-size: 14px;
        text-align: left;
        z-index: 100;
        &:before {
          content: "";
          display: block;
          position: absolute;
          top: -24px;
          right: 22px;
          width: 0;
          height: 0;
          background: transparent;
          border: 12px solid transparent;
          border-bottom: 12px solid #ddd;
        }
        &:after {
          content: "";
          display: block;
          position: absolute;
          top: -20px;
          right: 24px;
          width: 0;
          height: 0;
          background: transparent;
          border: 10px solid transparent;
          border-bottom: 10px solid #fff;
        }
      }
      li {
        padding: 15px;
        border-bottom: 1px solid #e0e0e0;
        &:last-child {
          border-bottom: none;
        }
        &:hover:not([role="separator"]):not(.separator) {
          background: #ededed;
          font-weight: bold;
          cursor: pointer;
        }
      }
    }
  }
}

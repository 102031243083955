@import "~jbc-front/styles/core";

:local {
  .tag {
    color: white;
    font-weight: bold;
    border-radius: 12px;
    display: inline-block;
    padding: 0px 13px;
    font-size: 12px;
    height: 20px;
    line-height: 19px;
  }

  .blue {
    background-color: colors(blue, default);
  }

  .red {
    background-color: colors(red, default);
  }

  .green {
    color: #116329;
    background-color: #dafbe1;
  }
}

@import '~jbc-front/styles/core';

:local {
  .selectedEmployeeRow {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    align-items: center;
    position: relative;
    @media #{$smartphone} {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .modeSelectContainer {
    width: 150px;
  }

  .yeaContentsWrap {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
  }
  .buttonArea {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;

    .buttonDl {
      margin-left: 0;
      margin-right: auto;
    }
  }
  .buttonList {
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media #{$smartphone} {
      flex-wrap: wrap;
      > div {
        min-width: 100%;
      }
      > div + div {
        margin-top: 10px;
      }
    }
  }
  .buttonRight {
    display: flex;
    gap: 10px;
  }
  .staffcodeColumn {
    flex-basis: 120px;
    width: 120px;
  }
  .nameColumn {
    flex-basis: 220px;
    width: 220px;
  }
  .employmentStatusColumn {
    flex-basis: 110px;
    width: 110px;
  }
  .positionColumn {
    flex-basis: 110px;
    width: 110px;
  }
  .noteColumn {
    flex-basis: 57px;
    width: 57px;
  }
  .emailColumn {
    flex-basis: 230px;
    width: 230px;
  }
  .statusColumn {
    flex-basis: 170px;
    width: 170px;
  }
  .arrowText {
    white-space: nowrap;
  }

  .statusDiv {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: none;
    border: none;
    align-items: center;
    &:focus {
      outline: 0;
    }
  }
  .pulldown {
    position: absolute;
    right: 14px;
  }

  .table {
    composes: m-table-list from global;
    composes: m-table-fixed from global;

    td {
      padding: 10px 10px 5px !important;
    }

    td.notNeedAdjDropDown {
      height: 50px;
      padding: 0 !important;
    }
  }
  .notNeedAdjDropDown {
    composes: dropdown from 'components/DropDownMenu.scss';
  }

  .memo {
    composes: u-cur-pointer from global;
    width: 30px;
    height: 30px;
    background-size: 30px 30px;
    background-repeat: no-repeat;
  }
  .errorMessage {
    width: 100%;
    color: colors(red);
    text-align: center;
  }
  .paginator {
    margin-bottom: 30px;
    @media #{$smartphone} {
      margin: auto auto 20px auto;
    }
  }
  .transitionOn {
    transform: translateY(0);
    opacity: 1;
  }
  .transitionOff {
    transform: translateY(100%);
    opacity: 0;
  }

  .base {
    display: inline-block;
    border-radius: 100px;
    min-width: 75px;
    height: 30px;
    cursor: pointer;
    font-size: 13px;
    font-weight: bold;
    line-height: 30px;
    text-align: center;
    padding: 0 15px;
    background: #fff;
  }

  $colorList:
    "blue" #3498db,
    "red" #d0021b,
    "yellow" #f7b805,
    "green" #60bd68,
    "purple" #da8ee7,
    "grey" #9b9b9b,
  ;
  @each $class, $color in $colorList {
    .#{$class}Tag {
      composes: base;
      color: $color;
      border: 1px solid $color;
      opacity: 0.5;
      &:hover {
        opacity: 1;
        background-color: rgba($color, 0.5);
        color: #fff;
        border-color: rgba($color, 0.5);
      }
      &.active {
        opacity: 1;
        background-color: $color;
        color: #fff;
        border-color: $color;
      }
    }
  }

  @media #{$smartphone} {
    .buttonArea {
      display: block;
      .buttonRight {
        > div {
          margin-right: auto;
        }
      }
      a {
        margin-right: 0;
        margin-left: 5px;
      }
    }
    .buttonDl {
      margin-bottom: 10px;
    }

    .searchBox {
      width: 100%;
    }
  }
}

:global {
  .color-not_requested {
    color: #d0021b;
  }
  .color-not_need_adj {
    color: #9b9b9b;
  }
  .color-in_progress,
  .color-rejected {
    color: #3498db;
  }
  .color-applying,
  .color-fixed {
    color: #f7b805;
  }
  .color-accepted {
    color: #60bd68;
  }
}

@import '~jbc-front/styles/core';

:local {
  .container {
    width: 615px;
  }
  .labelWithButton {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
}

@import "~jbc-front/styles/core";

:local {
  .base {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding: 13px 20px;
    width: 100%;
    background-color: #fff;
    align-items: center;
    color: #000;
    font-size: 14px;
    box-shadow: 0 1px 3px 0 #ddd;
    z-index: 100;
  }
  .logo {
    color: #007cff;
    margin-right: auto;
    display: flex;
    align-items: baseline;
  }
  .logoText {
    font-size: 11px;
    color: #a8a8a8;
    margin-left: 4px;
  }
  .base a {
    color: #000;

    &:hover {
      color: colors(blue);
    }
  }
  .brand {
    height: 20px;
  }
  @media #{$smartphone} {
    .brand {
      width: 120px;
    }
  }
}

:local {
  .cardWrap {
    background: #fff;
    border: solid 1px #ddd;
    border-radius: 0 0 4px 4px;
    margin: 120px 0 0 0;
    min-width: 700px;
    padding: 30px;
    text-align: center;
    > h2 {
      font-size: 21px;
      font-weight: normal;
      margin: 0 0 40px 0;
    }
    .description {
      font-size: 14px;
      margin: 5px 0 0 0;
    }
  }
}

@import "~jbc-front/styles/core";

:local {
  .main {
    margin-bottom: 20px;
    border-radius: 4px;
    padding: 30px;
    background-color: #fff;
  }
  .section {
    margin-top: 20px;
    border-top: 1px solid borderColors(base);
    padding-top: 30px;

    &:first-child {
      margin-top: 0;
      border-top: none;
      padding-top: 0;
    }
  }
  .category {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: bold;
  }

  @media #{$smartphone} {
    .main {
      padding: 15px;
    }
    .section {
      margin-top: 15px;
      padding-top: 20px;
    }
    .category {
      font-size: 16px;
    }
  }
}

@import "~jbc-front/styles/core";

:local {
  .formSection {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 615px;
  }

  .labelContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@import "~jbc-front/styles/core";

:local {
  .radioContainer {
    min-height: 36px;

    @media #{$smartphone} {
      min-height: 30px;
      flex-wrap: wrap;
      gap: 10px 40px;
    }
  }
}

@import "~jbc-front/styles/core";

:local {
  .preview {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .mainJobIncomeHeader {
    justify-content: space-between;
  }
}

@import "~jbc-front/styles/core";

:local {

  .add {
    border-radius: 6px;
    padding: 12px;
    text-align: center;
    cursor: pointer;
    @include dashed(textColors(sub));
  }
  .addFixed {
    composes: add;
    cursor: not-allowed;
  }

}



@import '~jbc-front/styles/core';

:local {
  .listItem {
    display: flex;
    > dt {
      width: 30%;
    }
    > dd {
      width: 70%;
    }
  }
  .panelWrap {
    margin: 0 auto 30px;
    border-radius: 4px;
    border: 1px solid #ddd;
    max-width: 100%;
    font-size: 13px;
  }
  .panelHeader {
    align-items: center;
    display: flex;
    justify-content: space-between;
    background-color: #fafafa;
    border-bottom: 1px solid #ddd;
    border-radius: 4px 4px 0 0;
    padding: 15px 15px 13px 15px;
    font-size: 14px;
    @media #{$smartphone} {
      flex-wrap: wrap;
      > div {
        flex-basis: auto;
        padding: 0 5px;
      }
    }
  }
  .panelTitle {
    font-size: 14px;
    font-weight: normal;
  }
  .dl {
    display: flex;
    padding: 10px;
    &:not(:last-child) {
      border-bottom: 1px solid #ddd;
    }
    > dt {
      width: 30%;
    }
    > dl {
      width: 70%;
    }
  }
}


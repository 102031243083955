@import "~jbc-front/styles/core";

:local {
  .container {
    background-color: #fafafa;
    border: 1px solid #ddd;
    border-radius: 4px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    gap: 10px;
  }

  .body {
    padding: 15px;
    img {
      width: 100%;
      + img {
        margin-top: 30px;
      }
    }
  }

  .icon {
    margin-left: 4px;
  }

  .toggleButton {
    min-width: 90px;
  }
}

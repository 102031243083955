@import "~jbc-front/styles/core";

:local {
  .label {
    font-size: 13px;
    font-weight: bold;
  }
  .note {
    font-size: 13px;
    color: #777777;
  }
  .description {
    margin-left: 138px;
  }
  .modalFooter {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }
}

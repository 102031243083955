@import "~jbc-front/styles/core";

:local {
  .body {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .list {
    width: 600px;
  }

  .item {
    border-bottom: 1px solid borderColors(base);
    cursor: pointer;

    &:hover {
      background: rgba(0,0,0,.07);
    }
  }

  .itemDetail {
    padding: 15px 10px;
    width: 100%;
    height: 100%;
  }
}

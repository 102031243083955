@import "~jbc-front/styles/core";

:local {
  .target {
    display: block;
    scroll-margin-top: 82px;
  }

  .anchor {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 12px 16px;
    &.activeScroll {
      background-color: colors(blue, default);
      font-weight: bold;
      color: white;
    }
    &:hover:not(.activeScroll) {
      background-color: #ededed;
    }
    .arrow {
      margin-left: auto;
      transform: rotate(270deg);
    }
  }
}

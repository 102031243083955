
@import "~jbc-front/styles/core";

:local {
  @mixin tabBar($borderColor) {
    position: sticky;
    top: 0;
    padding-top: 20px;
    margin-top: -20px;
    display: flex;
    justify-content: space-between;
    border-bottom: solid 2px;
    background-color: #f1f1f1;
    border-color: $borderColor;
    z-index: 1;
    &::after {
      content: "";
      position: absolute;
      bottom: -22px;
      display: block;
      width: 100%;
      height: 20px;
      background-color: #f1f1f1;
    }

    @media #{$smartphone} {
      position: relative;
      padding-top: 0;
      margin-top: 0;
      flex-direction: column-reverse;
      z-index: 2;
    }
  }

  .blueTabBar {
    @include tabBar(colors(blue, default));
  }

  .greenTabBar {
    @include tabBar(#60bd68);
  }

  .yellowTabBar {
    @include tabBar(colors(yellow, default));
  }

  .tabContainer {
    display: flex;
    gap: 20px;

    @media #{$smartphone} {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 10px;
    }
  }

  .toolContainer {
    display: flex;
    gap: 8px;

    @media #{$smartphone} {
      position: relative;
      margin-bottom: 20px;
      margin-left: auto;
    }
  }

  @mixin tab {
    min-width: 140px;
    padding: 10px;
    border-radius: 4px 4px 0px 0px;
    font-weight: bold;
    text-align: center;
    background-color: #ddd;

    @media #{$smartphone} {
      min-width: 100px;
    }
  }

  @mixin activeTab($backgroundColor) {
    @include tab;
    color: white;
    font-weight: bold;
    background-color: $backgroundColor;
  }

  .tab {
    @include tab;
    color: textColors(sub, hover);
    cursor: pointer;

    &:hover {
      color: #fff;
      @include activeTab(colors(blue, default));
    }

    &.result {
      &:hover {
        color: #fff;
        @include activeTab(#60bd68);
      }
    }
  }

  .blueTab {
    @include activeTab(colors(blue, default));
  }

  .greenTab {
    @include activeTab(#60bd68);
  }

  .yellowTab {
    @include activeTab(colors(yellow, default));
  }

  .disabled {
    @include tab;
    background-color: #e6e6e6;
    color: #a6a6a6;
    
    &:hover {
      cursor: not-allowed;
      color: #8a8a8a;
    }
  }
}

@import "~jbc-front/styles/core";

:local {
  .titleTh {
    th {
      &:nth-child(2) {
        border-radius: 4px 0 0 0;
      }

      &:last-child {
        border-radius: 0 4px 0 0;
      }
    }
  }

  .invisible {
    background-color: white !important;
    border: none !important;
  }

  .targetTh {
    border-radius: 4px 0 0 0;
  }

  .noPayroll {
    background: #fafafa;
    padding: 0 !important;
    height: 1px;
  }

  .amountTh {
    border-radius: 0 0 0 4px;
    border-top: 0 !important;
  }

  .input {
    width: 200px;
    margin-left: 55px;
    margin-right: 55px;
    align-self: center;

    > input {
      text-align: center;
    }
  }
}

@import "~jbc-front/styles/core";

:local {

  .icon {
    position: relative;
    top: -1px;
    margin-right: 5px;
    
    @media #{$smartphone} {
      display: inline-flex;
      min-width: 15px;
      max-width: min-content;
      vertical-align: middle;
    }
  }

  .main {
    border-radius: 4px;
    padding: 30px;
    background-color: #fff;

    @media #{$smartphone} {
      padding: 30px 15px;
    }
  }

  .title {
    composes: m-title-sub from global;
    margin-bottom: 30px;
    padding-top: 0;
    font-weight: 500;
    text-align: center;
  }

  .status {
    margin: 0 auto 30px;
    width: 90%;
    font-size: 18px;
    text-align: center;

    @media #{$smartphone} {
      font-size: 16px;
      width: 100%;
    }
  }
  .inprogress {
    padding: 10px 0;
    background-color: backgroundColors(info);
    color: colors(blue);
  }
  .confirmed {
    padding: 10px 0;
    background-color: backgroundColors(success);
    color: colors(green);
  }
  .confirmed_text {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 500;
  }
  .img {
    min-width: 100px;
    text-align: right;
    margin-left: auto;
    
    @media #{$smartphone} {
      margin-top: 5px;
    }
  }
  .newline {
    @media #{$smartphone} {
      display: inline-flex;
      max-width: min-content;
      min-width: 92%;
    }
  }
}

@import "~jbc-front/styles/core";

:local {
  .container {
    padding: 10px;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(67, 48, 48, 0.06);
    word-break: break-all;
  }

  .mainContent {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: space-between;
  }

  .left {
    max-width: 230px;
    width: 100%;
  }
  
  .right {
    max-width: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: auto;
  }

  .fullname {
    max-width: 230px;
    max-height: 60px;
    font-size: 22px;
    line-height: 1.14;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    // FIXME: autoprefixer 9.6.1以上に上げたらignoreは削除する
    // https://github.com/postcss/autoprefixer/releases/tag/9.6.1
    /*! autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  .subContent {
    display: flex;
    padding-top: 10px;
    margin-top: 10px;
    align-items: center;
    border-top: solid 1px borderColors(base, default);
  }

  .blueTag {
    background-color: blue;
  }

  $colorList:
    "blue" #3498db,
    "red" #d0021b,
    "yellow" #f7b805,
    "green" #60bd68,
    "purple" #da8ee7,
    "grey" #9b9b9b,
  ;
  @each $status, $color in $colorList {
    .#{$status} {
      display: inline-block;
      height: 30px;
      min-width: 75px;
      padding: 0 15px;
      color: #fff;
      border-radius: 100px;
      font-size: 13px;
      font-weight: 700;
      line-height: 30px;
      text-align: center;
      background-color: $color;
    }
  }

  .commentIcon {
    position: relative;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    background-color: #eee;
    border-radius: 100%;
    cursor: pointer;

    &.hasComments {
      background-color: #3498db;
      * {
        fill: #fff;
      }
    }
    
    .redPoint {
      background: #fff;
      border-radius: 14px;
      color: red;
      font-size: 12px;
      height: 16px;
      position: absolute;
      right: -2px;
      text-align: center;
      top: -4px;
      width: 16px;
      font-style: normal;
    }
  }
}

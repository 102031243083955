@import "~jbc-front/styles/core";

:local {
  .loading {
    composes: sk-circle from global;
    margin: 0 5px 0 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    &>:global(.sk-child):before {
      background-color: #fff;
    }
  }

  .loadingButton {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .body {
    font-size: 14px;
    label {
      font-size: 14px;
    }
  }

  .date {
    white-space: nowrap;
  }

  .selectWrap {
    width: 300px;
  }

  .red {
    color: colors(red);
  }
}


@import "~jbc-front/styles/core";

:local {
  .separator {
    background-color: borderColors(sub, default);
    border: none;
    height: 1px;
    width: 100%;
  }
}

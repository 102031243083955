@import "~jbc-front/styles/core";

:local {
  .redDescription {
    font-size: 14px;
    color: #E61345;
  }
  .primaryPanelHeader {
    @media #{$smartphone} {
      position: sticky;
      top: 0;
      z-index: 1;
    }
  }
}
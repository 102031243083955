@import '~jbc-front/styles/core';

:local {
  .floatingButtonSpace {
    display: flex;
    max-width: 650px;
    width: 100%;
    grid-gap: 16px;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    padding: 10px;
    margin: auto;
    position: sticky;
    bottom: 20px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    z-index: 2;
    transition: all 250ms;
    @media #{$smartphone} {
      grid-gap: 10px;
      bottom: 70px;
      padding: 10px;
      z-index: 1;
    }
  } 
}

@import "~jbc-front/styles/core";

:local {

  .mainFlex {
    max-width: 1030px;
    width: 100%;
    margin: auto;
  }
  .mainGrid {
    display: flex;
    gap: 30px;

    @media #{$smartphone} {
      grid-template-columns: 1fr;
    }
  }

  .form {

    .setting {
      display: grid;
      flex-direction: column;
      gap: 30px;
    }

    @media #{$smartphone} {
      section {
        max-width: 100%;

        > div {
          padding: 15px;
          margin: 0;
        }
      }
    }
  }

  .wrap {
    & > section {
      max-width: 100%;
      margin: 0;
    }

    & > a {
      display: block;
      position: relative;
      top: -20px;
      visibility: hidden;
    }

    @media #{$smartphone} {
      margin-bottom: 30px;
    }
  }

  .title {
    margin-bottom: 15px;
    font-size: 15px;
    font-weight: bold;

    @media #{$smartphone} {
      font-size: 14px;
    }
  }

  .listContainer {
    margin-top: 40px;
  }

  .list {
    margin: 30px 0 10px;
    font-size: 15px;
    font-weight: bold;

    @media #{$smartphone} {
      font-size: 14px;
    }
  }

  .select {
    margin-top: 40px;

    @media #{$smartphone} {
      margin-top: 20px;
    }
  }

  .button {
    padding: 20px 0;
    width: 260px;
    height: 125px;
    font-size: 16px;
    line-height: 1.38;

    span {
      font-weight: bold;
    }

    @media #{$smartphone} {
      padding: 10px;
      width: 50%;
      height: auto;
      font-size: 14px;
    }
  }

  .side {
    position: sticky;
    top: 20px;
    min-width: 280px;
    margin-bottom: 30px;
    align-self: start;
    z-index: 1;
  }

  @media #{$smartphone} {
    .side {
      display: none;
    }
  }

  .icon {
    margin-bottom: 10px;
  }
  .blue {
    color: colors(blue);
  }
  .red {
    color: colors(red);
  }

}

@import "~jbc-front/styles/core";

:local {
  .description {
    margin-top: 85px;
  }
  .alert {
    color: colors(red);
  }
}


@import "~jbc-front/styles/core";

:local {
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin: 50px 0px;

    @media #{$smartphone} {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
     }
  }
}

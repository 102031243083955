@import "~jbc-front/styles/core";
:local {
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 100px;
  }

  .preparing {
    background-color: white;
    padding: 100px 200px;
    font-size: 30px;
  }
}

@import "~jbc-front/styles/core";

:local {
  .common {
    padding: 2px 5px;
    margin-left: -5px;
    margin-right: -5px;
    line-height: 1;
    border-radius: 2px;
  }

  .input {
    composes: common;
    margin-top: -5px;
    color: #116329;
    background-color: #dafbe1;
  }

  .source {
    composes: common;
    margin-bottom: -5px;
    margin-top: 2px;
    color: #666;
    background-color: #f0f0f0;
  }

  .explainColors {
    display: flex;
    flex-direction: column;
    padding: 2px 4px;
    border-radius: 2px;
    background-color: #fff;
    border: 1px solid #999;

    > span {
      margin: 0;
      font-size: 12px;
      padding: 1.5px 7px;
      &.source {
        margin-top: 2px;
      }
    }
  }

  .dl {
    flex-grow: 1;
  }
}

@import "~jbc-front/styles/core";

:local {
  .description {
    font-size: 14px;
    text-align: left;
    white-space: pre-wrap;
  }

  .caution {
    color: colors(red)
  }
}

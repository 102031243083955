@import "~jbc-front/styles/core";

:local {
  .dl {
    display: flex;
    flex-wrap: nowrap;
    font-size: 13px;
    width: 100%;
    word-break: break-all;
  }

  .fileDl {
    @media #{$smartphone} {
      flex-direction: column;
      dt, dd {
        width: 100%;
      }
    }
  }

  .dt {
    display: flex;
    border-bottom: 1px solid borderColors(base);
    padding: 10px;
    width: 150px;
    font-weight: normal;
    background-color: #fafafa;
    letter-spacing: normal;
    @media #{$smartphone} {
      background-color: initial;
    }
  }
  .dd {
    border-bottom: 1px solid borderColors(base);
    padding: 10px;
    flex-grow: 1;
    width: calc((100% - 200px) / 2);
    white-space: pre-line;
    & > span {
      display: block;
    }
    div > span {
      display: inline-block;
    }
  }
  .required {
    color: #e61345;
    background-color: #f8e8ec;
    &::after {
      content: "*";
      display: inline-block;
    }
  }
}

@import '~jbc-front/styles/core';

:local {
  .paginatorWithResult {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
  }
  .row {
    display: flex;
    width: 100%;
    > div {
      &:nth-child(1) {
        width: 15%;
      }
      &:nth-child(2) {
        width: 70%;
      }
      &:nth-child(3) {
        width: 15%;
      }
    }
    @media #{$smartphone} {
      flex-wrap: wrap;
      > div {
        flex-basis: 100%;
        &:nth-child(1) {
          order: 3;
          margin-top: 15px;
        }
        &:nth-child(3) {
          margin-top: 15px;
        }
      }
    }
  }
}

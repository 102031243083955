@import "~jbc-front/styles/core";

:local {

  .menuBtn {
    display: none;
  }

  @media #{$smartphone} {

    .menuBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      bottom: 15px;
      left: 15px;
      border-radius: 25px;
      width: 48px;
      height: 48px;
      background-color: colors(blue);
      color: #fff;
      cursor: pointer;
      z-index: 110;

      rect {
        fill: colors(blue);
      }
    }

  }

}

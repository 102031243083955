@import "~jbc-front/styles/core";

:local {
  .cardSection {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin: 55px 0 0 0;
    padding: 45px;
  }
  .cityAndAddressWrap {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 0 30px 0;
  }
  .cityAndAddress {
    font-size: 20px;
    font-weight: 500;
  }
  .destination {
    border: solid 2px #ccc;
    border-radius: 20px;
    margin: 0 0 26px 0;
    padding: 8px;
    text-align: center;
  }
  .taxTable {
    &:not(:last-child) {
      margin: 0 0 36px 0;
    }
  }
  .taxTitleWrap {
    margin: 0 0 22px 0;
  }
  .taxTitle {
    align-items: center;
    display: flex;
    .taxPeople {
      font-size: 20px;
      margin: 0 0 0 10px;
    }
  }
  .tableBodyScroll {
    thead,
    tbody {
      display: block;
    }
    tbody {
      max-height: 490px;
      overflow-y: auto;
    }
  }
  .destinationWarning {
    color: colors(red);
    margin-top: 7px;
  }
  .destinationAddress {
    white-space: pre-line;
  }
}


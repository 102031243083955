@import "~jbc-front/styles/core";

:local {
  .alert {
    padding: 30px;
    background-color: #f9e8ec;
    border-radius: 4px;
  }
  .alert h1 {
    font-size: 18px;
    color: #e61345;
    text-align: center;
    margin: 0;
  }
  .alert p {
    margin-top: 10px;
    font-size: 12px;
  }
  .officeName {
    display: inline-block;
  }
  .primaryPanelHeader {
    @media #{$smartphone} {
      position: sticky;
      top: 0;
      z-index: 1;
    }
  }
}
@import "~jbc-front/styles/core";

:local {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .iconContainer {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .icon {
    width: 20px;
    height: 20px;
    color: textColors(sub, default);
    cursor: pointer;
    &.editSquare {
      color: colors(blue, default);
    }
    &.disabled {
      color: colors(gray, disabled);
      cursor: not-allowed;
    }
  }

  .hiddenHeader {
    border: none;
    border-radius: 4px;
  }

  .hiddenBody {
    height: 0;
    overflow: hidden;
    padding: 0;
  }

  .form {
    background-color: #f3f8ff;
  }

  .disableEditSquare {
    &:hover {
      .disabledReason {
        display: block;
      }
    }
  }

  .disabledReason {
    display: none;
    position: relative;
    z-index: 99;
  }

  .disabledReasonShape {
    position: absolute;
    top: 5px;
    left: 3px;
    width: 15px;
    height: 15px;
    background: #666;
    transform: rotate(45deg);
  }

  .disabledReasonMessage {
    position: absolute;
    left: -63px;
    bottom: -52px;
    padding-top: 13px;
    width: 150px;
    min-height: 40px;
    background: #666;
    color: #fff;
    font-size: 12px;
    text-align: center;
    white-space: pre-line;
  }

  .sectionHeader {
    @media #{$smartphone} {
      position: sticky;
      top: 0;
      z-index: 1;
    }
  }
}

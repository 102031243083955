:local {
  .contents {
    display: flex;
    flex-direction: column;
  }
  .title {
    font-size: 33px;
    font-weight: 500;
    color: #333;
    text-align: center;
    margin: 50px 0 150px 0;
  }
  .cardWrap {
    background: #fff;
    border: solid 1px #ddd;
    border-radius: 0 0 4px 4px;
    font-size: 14px;
    line-height: 1.4;
    max-width: 600px;
    padding: 30px;
    > p {
      margin: 0 0 20px 0;
    }
  }
}

@import "~jbc-front/styles/core";

:local {
  .canvasContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .canvas {
    width: auto;
    max-width: 100%;
    padding: 10px;
    margin-bottom: 60px;
  }

  .pdfContainer {
    width: auto;
    max-height: 520px;
    background-color: #525659;
  }

  .pdfControllerContainer {
    height: auto;
    top: inherit;
    bottom: 5px;
  }

  .pdfController {
    position: relative;
    bottom: inherit;
  }
}

@import "~jbc-front/styles/core";

:local {

  .action {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;

    div {
      margin-right: 10px;
    }
  }

  .table {
    div {
      margin-bottom: 0;
    }

    th:nth-child(4) {
      border-left: none;
    }
  }

  .box {
    margin: 0 auto 30px;
    border-radius: 4px;
    border: 1px solid borderColors(base);
    max-width: 621px;
    font-size: 13px;
  }

  .designationNumber {
    text-align: left;
  }

  .default {
    border: none;
    &:hover {
      border: none;
      color: #666;
    }
  }

  .default[disabled] {
    border: none;
    &:hover {
      border: none;
      color: #dedede;
    }
  }

  .noteContainer {
    position: relative;

    &:hover {
      .note {
        display: block;
      }
    }
  }
  .note {
    display: none;
    position: absolute;
    top: -75px;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    width: auto;
    border-radius: 3px;
    padding: 15px 20px;
    background: #666;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    line-height: 19px;
    text-align: left;
    white-space: nowrap;

    &:after {
      position: absolute;
      content: '';
      bottom: -7px;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      margin-left: -7px;
      width: 15px;
      height: 15px;
      background: #666;
      transform: rotate(45deg);
    }
  }
}

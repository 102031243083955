@import "~jbc-front/styles/core";

:local {
  .active {
    background-color: colors(blue, default);
    font-weight: bold;
    padding: 0px;

    & > * {
      color: white;

      &:hover {
        color: white;
      }
    }
  }

  .resultNavigator {
    @media #{$smartphone} {
      display: none;
    }
  }

  .item {
    padding: 0;
  }
}

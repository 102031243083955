@import "~jbc-front/styles/core";

:local {
  .employeeNavigator {
    @media #{$smartphone} {
      display: none;
    }
  }

  .item {
    padding: 0;
  }
}

@import "~jbc-front/styles/core";

:local {
  .lead {
    padding: 30px 0px;
    color: colors(blue, active);
    font-size: 24px;
    font-weight: bold;
    text-align: center;

    @media #{$smartphone} {
      padding: 20px 0px;
      font-size: 18px;
    }
  }
}

@import '~jbc-front/styles/core';

:local {
  .warning {
    color: colors(red);
  }

  .modalBody {
    padding: 30px;
    border-bottom: 1px solid borderColors(base);
    background-color: #fff;
    color: #333;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: normal;
    max-height: calc(100vh - 200px);
    overflow: visible;
  }

  .modalContent {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .annotation {
    color: colors(red);
    font-weight: bold;
  }
}

@import "~jbc-front/styles/core";

:local {
  .progress {
    padding: 25px 0 45px;
  }

  .contentContainer {
    display: flex;
    justify-content: space-between;

    @media #{$smartphone} {
      display: block;
    }
  }

  .main {
    width: 660px;

    @media #{$smartphone} {
      width: 100%;
    }
  }
  .aside {
    width: 280px;

    @media #{$smartphone} {
      border-top: 1px solid borderColors(base);
      padding-top: 30px;
      width: 100%;
    }
  }
}

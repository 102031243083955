@import "~jbc-front/styles/core";

:local {
  .container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    min-width: 342px;
    overflow-x: auto;
  }

  .fontBolder {
    font-weight: bolder;
  }
}

@import "~jbc-front/styles/core";

:local {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
  }

  .body {
    height: calc(100% - 50px);
  }

  .iconContainer {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .icon {
    color: colors(gray, default)
  }

  .hiddenHeader {
    border: none;
  }

  .hiddenBody {
    height: 0;
    overflow: hidden;
    padding: 0;
  }
}

@import '~jbc-front/styles/core';

:local {
  .statusDiv {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: none;
    border: none;
    align-items: center;
    &:focus {
      outline: 0;
    }
  }
  .pulldown {
    position: absolute;
    right: 14px;
  }

  .table {
    composes: m-table-list from global;
    composes: m-table-fixed from global;

    td {
      padding: 10px 10px 5px !important;
    }

    td.notNeedAdjDropDown {
      height: 50px;
      padding: 0 !important;
    }
  }
  .notNeedAdjDropDown {
    composes: dropdown from '@jbc-year-end-adj/2024/components/DropDownMenu.scss';
  }
}

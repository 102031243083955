
@import "~jbc-front/styles/core";

:local {
  .sidebar {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  .open {
    transform: rotateX(180deg);
  }

  .close {
    transform: rotateX(0deg);
  }

  .headerContent {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .item {
    color: #3498db;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: backgroundColors(hover);
    }
  }

  .currentItem {
    color: #333333;
    font-weight: bold;
    display: flex;
    align-items: center;
    cursor: default;

    &:hover {
      background-color: white;
    }
  }

  .disableItem {
    background-color: borderColors(base, default);
    border-color: white;
    color: #b1b1b1;
    cursor: not-allowed;

    &:hover {
      background-color: borderColors(base, default);
    }
  }

  .questionStep {
    padding: 0px 5px;
    white-space: nowrap;
  }

  .currentIndicator {
    margin-left: auto;
    transform: rotate(270deg);
    color: #CCCCCC;
  }
}

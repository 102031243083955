@import "~jbc-front/styles/core";

:local {
  .sectionBody {
      padding-top: 6px;
  }

  .titleTh {
    th {
      &:nth-child(2) {
        border-radius: 4px 0 0 0;
      }

      &:last-child {
        border-radius: 0 4px 0 0;
      }
    }
  }

  .invisible {
    visibility: hidden;
  }

  .targetTh {
    border-radius: 4px 0 0 0;
  }

  .rollback {
    text-align: right;
    padding-bottom: 6px;
  }

  .noPayroll {
    background: #fafafa;
    padding: 0 !important;
    height: 1px;
  }

  .description {
    cursor: pointer;
    width: 100%;
    height: 100%;

    .text {
      text-align: left !important;
    }
  }

  .targetTd {
    position: relative;

    .editIcon {
      position: absolute;
      right: 5%;
      top: 50%;
      transform: translateY(-50%);
      color: colors(gray, default);
    }
  }

  .amountTh {
    border-radius: 0 0 0 4px;
    border-top: 0 !important;
  }
}

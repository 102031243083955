@import "~jbc-front/styles/core";

:local {

  .wrap {
    margin: 40px auto 0;
    max-width: 600px;
  }
  .head {
    padding: 8px 0;
    border-radius: 4px 4px 0 0;
    background-color: #a4a4a4;
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
  }
  .body {
    padding: 40px 45px;
    border: 1px solid #a4a4a4;
    border-radius: 0 0 4px 4px;
    @media #{$smartphone} {
      padding: 15px;
    }
  }

  .label {
    display: flex;
    margin: 0 0 30px;
    font-size: 14px;
    font-weight: bold;
  }
  .number {
    display: block;
    border-radius: 2px;
    min-width: 24px;
    height: 24px;
    background-color: #9f9f9f;
    color: #fff;
    line-height: 24px;
    text-align: center;
  }
  .detail {
    margin-left: 15px;
    padding-top: 3px;
    width: 100%;
    @media #{$smartphone} {
      margin-left: 10px;
      padding-top: 0px;
    }
  }
  .text {
    margin-bottom: 20px;
  }
  .important {
    color: colors(red);
  }
  .description {
    margin-top: 85px;
  }
  .large {
    min-width: 280px;
    display: inherit;
    margin: 0 auto;
  }
}

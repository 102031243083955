@import "~jbc-front/styles/core";

:local {
  .filePreview {
    text-align: right;
    margin-top: 10px;
  }

  .inheritMask {
    path {
      mask: inherit;
    }
  }

  .draggable {
    padding-bottom: 20px;
    background-color: #fff;
    box-shadow: 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 11px 15px -7px rgba(0, 0, 0, 0.20);
    border-radius: 4px;
    z-index: 500;
  }

  .draggableInner {
    height: 100%;
    padding: 20px 20px 0 20px;
    overflow: auto;
  }

  .header {
    display: flex;
    padding-bottom: 10px;
    align-items: center;
    font-weight: bolder;
  }

  .closeButton {
    margin-left: auto;

    > span {
      margin: 0;
    }
  }

  .preview {
    width: 100%;
    height: 92%;
    margin: auto;
    display: block;
    object-fit: contain;
    cursor: default;
    pointer-events: none;
    
    @media #{$smartphone} {
      width: 100%;
    }
  }
  object.preview {
    height: calc(100% - 42px);
    pointer-events: all;
    @media #{$smartphone} {
      min-height: calc(100vh - 400px);
    }
  }

  .canvas {
    > canvas {
      padding: 10px !important;
    }
  }
  
}

@import "~jbc-front/styles/core";

:local {

  .base {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding: 13px 20px;
    width: 100%;
    background-color: #fff;
    align-items: center;
    color: #000;
    font-size: 14px;
    box-shadow: 0 1px 3px 0 #ddd;
    z-index: 100;
  }
  .logo {
    color: #007cff;
    margin-right: auto;
    display: flex;
    align-items: baseline;
  }
  .logoText {
    font-size: 11px;
    color: #a8a8a8;
    margin-left: 4px;
  }
  .base a {
    color: #000;

    &:hover {
      color: colors(blue);
    }
  }
  .brand {
    height: 20px;
  }
  .item {
    &:not(:first-child) {
      padding-left: 10px;
    }
    &:not(:last-child):after {
      content: "|";
      padding-left: 10px;
    }
    svg {
      color: #999;
    }
  }
  .client {
    composes: item;
    align-items: center;
    display: flex;
  }
  .applicableOffice {
    display: flex;
    align-items: center;
    composes: item;
  }
  .user {
    composes: item;
  }
  .link {
    padding-left: 10px;
    color: #aaa;
    text-decoration: none;

    &:hover {
      color: colors(blue);
      cursor: pointer;
    }
  }
  .email {
    margin-left: 8px;
  }
  .help {
    composes: item;
    padding-top: 1px;

    svg {
      top: 1px;
      margin-right: 5px;
      color: colors(blue);
    }
  }

  .customerCodeStyle {
    display: flex;
    flex-wrap: wrap;
    &:not(:last-child) { // 自身の下に要素があれば
      border-bottom: 1px solid #ddd;
      margin-bottom: 10px;
    }
    > span {
      min-width: 60%;
      padding: 10px 15px;
      border: 1px solid #3498db;
      border-radius: 30px;
      margin: 10px auto;
      font-weight: bolder;
      text-align: center;
    }
  }

  .notVerified {
    margin-top: -13px;
    margin-bottom: -13px;
    margin-right: 10px;
    color: colors(red);
    font-size: 13px;
  }

  @media #{$smartphone} {
    .brand {
      width: 120px;
    }
    .email {
      display: none;
    }
    .pulldown {
      display: none;
    }
    .client {
      display: none;
    }
    .trial {
      //display: none;
      min-width: 130px;
      padding: 0 5px;
      font-size: 12px;
    }
    .notVerified {
      display: none;
    }
  }

}

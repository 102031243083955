@import "~jbc-front/styles/core";

:local {
  .primaryPanelHeader {
    @media #{$smartphone} {
      position: sticky;
      top: 0;
      z-index: 1;
    }
  }
}
@import "~jbc-front/styles/core";

:local {

  .base {
    margin-bottom: 20px;
  }

  .title {
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid borderColors(base);
    border-radius: 4px 4px 0 0;
    padding: 13px 10px;
    background: backgroundColors(base);
    font-size: 14px;
    cursor: pointer;
  }

  .title_opened {
    composes: title;
  }

  .title_closed {
    composes: title;
    border-radius: 4px;
  }

  .pulldown {
    position: absolute;
    top: 50%;
    right: 15px;
    margin-top: -10px;
    color: #333;
    text-indent: 0;
  }

  .pulldown_opened {
    composes: pulldown;
    transform: rotate(180deg);
  }

  .pulldown_closed {
    composes: pulldown;
  }

  .body {
    max-height: 400px;
    overflow: auto;
    border: 1px solid borderColors(base);
    border-top: 0;
    border-radius: 0 0 4px 4px;
  }

  .row {
    position: relative;
    border-bottom: 1px solid borderColors(base);
    background-color: #fff;
    &:last-child {
      border-bottom: 0;
    }
    > a {
      display: grid;
      padding: 8px 15px;
      font-size: 13px;
      grid-template-columns: 60% 40% 1fr;
      align-items: flex-start;
      .left {
        display: flex;
        align-items: center;
        .employeeInfo {
          display: flex;
        }
        div + div {
          margin-left: 5px;
        }
        @media #{$smartphone} {
          flex-direction: column;
          align-items: flex-start;
        }
      }
      .right {
        display: flex;
        justify-content: right;
        align-items: center;
        @media #{$smartphone} {
          flex-direction: column;
          align-items: flex-end;
          grid-column: 1;
          grid-row: 3;
          margin-top: 4px;
        }
      }
      .main {
        margin-top: 4px;
        grid-column: 1 / 3;
        @media #{$smartphone} {
          grid-column: 1;
        }
      }
      &:hover {
        background-color: backgroundColors(hover);
      }
      @media #{$smartphone} {
        grid-template-columns: minmax(100%, 1fr);
      }
    }
  }

  .row_title {
    display: flex;
    margin-bottom: 4px;

    > div {
      margin: 0px 4px;
    }

    .date {
      color: dimgray;
    }
  }

  .row_body {
    padding: 2px;
    white-space: pre-wrap;
  }

  @each $key, $type in $memoColors {
    .memo_#{$key} {
      color: map-get($type, default);
      font-size: 15px;
      margin-right: 5px;
    }
  }
}



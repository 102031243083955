@import "~jbc-front/styles/core";

:local {
  .preview {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .mainJobIncomeHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

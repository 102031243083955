@import "~jbc-front/styles/core";

:local {
  .overflow {
    overflow-x: auto;
  }

  .expandableTable {
    position: relative;

    th {
      &:first-child {
        border-radius: 4px 0 0 0;
      }

      &:last-child {
        border-radius: 0 4px 0 0;
      }
    }

    th {
      white-space: nowrap;
      min-width: fit-content;
    }

    th, td {
      padding: 16px;
    }

    tr:last-child td:last-child {
      border-radius: 0 0 4px 4px;
    }

    td:last-child {
      padding: 16px;
    }

    .spouseColumn {
      padding-left: 35px;
      text-align: left;
      white-space: nowrap;
      min-width: fit-content;
    }

    .relationTh {
      padding-left: 35px;
      text-align: left;
    }

    .fullNameTh {
      text-align: left;
    }
  }

  .toggleColumn {
    display: flex;
    gap: 8px;
    align-items: center;
    white-space: nowrap;
    min-width: fit-content;
  }

  .expandableRow {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 15px 25px;

    @media #{$smartphone} {
      padding: 0;
    }
  }

  .fullNameTd {
    text-align: left;
    min-width: 150px;
    max-width: 400px;
  }

  .genderTd {
    white-space: nowrap;
    min-width: fit-content;
  }

  .row {
    &:hover {
      background-color: #f6f6f6;
      cursor: pointer;

      & .icon {
        color: colors(blue, default);
      }
    }
  }

  .expand {
    font-weight: bold;
    background-color: #f6f6f6;
    tr, td {
      border-bottom: none;
    }
  }

  .incomeDetailHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .incomeIcon {
    &:hover {
      cursor: pointer;
    }
  }
}

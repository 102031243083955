@import "~jbc-front/styles/core";

:local {

  .mainFlex {
    max-width: 930px;
    width: 100%;
    flex-direction: column;
    margin: auto;
  }

  .mainGrid {
    display: grid;
    grid-template-columns: 300px 1fr;
    @media #{$smartphone} {
      grid-template-columns: 1fr;
    }
  }

  .side {
    position: sticky;
    top: 80px;
    width: 300px;
    margin-bottom: 30px;
    align-self: start;
    z-index: 1;
  }

  .alertText {
    color: colors(red);
  }

  @media #{$smartphone} {
    .side {
      display: none;
    }
  }

  .naviLink {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .navi {
    max-height: calc(100vh - 63px);
    overflow-y: auto;
  }

  .editTitle {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    width: 100%;
    background-color: #f1f1f1;
    z-index: 2;

    .name {
      font-size: 28px;
      font-weight: 500;
      margin: 0px 8px;
    }

    .staffCode {
      font-size: 15px;
      font-weight: 500;
      padding: 3px 0;
      align-self: flex-end;
    }
  }

  .delete {
    display: inline-flex;
    margin-top: 20px;
    cursor: pointer;

    .icon {
      display: flex;
      border: 1px solid #ccc;
      border-radius: 2px;
      width: 20px;
      height: 20px;
      background-color: #fff;
      color: #aaa;
      align-items: center;
      justify-content: center;
    }
    .text {
      margin-left: 5px;
      font-size: 13px;
      line-height: 20px;
    }
  }

  .buttonContainer {
    display: flex;
    justify-content: center;
    gap: 20px;
  }

  @media #{$smartphone} {
    .buttonContainer {
      flex-wrap: wrap;
    }
  }

  .commentBoxList {
    max-width: 930px;
    width: 100%;
    margin-bottom: 20px;
    
    > div {
      max-height: 400px;
      overflow: auto;
    }

    .date {
      margin-left: 10px;
      color: #666;
    }
    .comment {
      margin-top: 5px;
      display: block;
      word-break: break-word;
      white-space: pre-line;
    }
    .commentIcon {
      margin-right: 5px;
      color: #999;
    }
  }

  .formBoxList {
    margin: 0;
    margin-left: 15px;
    flex: 0 0 870px;
    @media #{$smartphone} {
      margin-left: 0;
    }
    form section {
      max-width: 100%;
    }
  }
  .forArchorTarget > a {
    display: block;
    position: relative;
    top: -80px;
    visibility: hidden;
  }
}

@import "~jbc-front/styles/core";

:local {
  .filteringTag {
    margin: 2px 4px;
    &:first-child {
        margin-left: 0;
    }
  }

  .filteringTagDefault {
    composes: default from 'jbc-front/components/Tag.scss';
    &:hover {
      border: 1px solid borderColors(base);
      background-color: #e8f5fe;
      color: colors(blue);
    }
  }

  .filteringTagSelected {
    composes: blue from 'jbc-front/components/Tag.scss';
    &:hover {
      background-color: #e8f5fe;
    }
  }

  .SearchResultUpdate {
    color: #666;
  }
  
  .searchEmploymentStatusField {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    margin-bottom: 5px;
  }

  .filteringTagBox {
    > p {
      margin-top: 5px;
      text-align: center;
      color: #666;
      line-height: 1;
    }
  }
}
@import "~jbc-front/styles/core";

:local {
  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    li {
      width: 420px;
    }
  }
  .item {
    display: block;
    height: 100%;
    padding: 20px 10px;

    &:hover {
      background-color: backgroundColors(hover);
    }
  }

  .definition {
    dt {
      color: colors(blue);
      font-size: 16px;
      font-weight: bold;
    }
    dd {
      margin-top: 5px;
    }
  }
  .icon {
    display: inline-block;
    margin-right: 5px;
    color: #000;
    transform: rotate(-90deg);
  }

  @media #{$smartphone} {
    .container {
      display: block;

      li {
        width: 100%;

        a {
          padding: 10px 5px;
        }
      }
    }
    .definition {
      dt {
        font-size: 15px;
      }
    }

  }
}

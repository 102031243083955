@import "~jbc-front/styles/core";

:local {
  .bar {
    background: #fff;
    border-radius: 5px;
    width: 100%;
    height: 10px;
  }

  .value {
    height: 10px;
    background: colors(blue);
    border-radius: 5px;
  }

  .progress {
    width: 100%;
    display: inline-block;
  }
}

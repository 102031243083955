@import "~jbc-front/styles/core";

:local {
    .bar {
        background: #fff;
        border-radius: 5px;
        width: 100%;
        height: 10px;
    }
    .barLarge {
        composes: bar;
        border-radius: 6px;
        height: 12px;
    }
    .value {
        height: 10px;
        background: colors(blue);
        border-radius: 5px;
    }
    .valueLarge {
        composes: value;
        border-radius: 6px;
        height: 12px;
    }
    .progress {
        width: 100%;
        display: inline-block;
    }
}

@import '~jbc-front/styles/core';

:local {
  .yeaContentsWrap {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
  }
  .lead {
    margin: 0 auto;
    padding: 10px 0 40px;
    width: 730px;
    font-size: 13px;
    font-weight: 500;
    text-align: center;

    p {
      margin-bottom: 5px;
    }

    @media #{$smartphone} {
      padding: 0 10px;
      width: 100%;
      text-align: left;
    }
  }
  .csvDownloadBtn {
    @media #{$smartphone} {
      margin: 10px auto;
      text-align: center;
    }
  }
  .buttons {
    margin: auto 0;

    > div {
      margin-right: 5px;
    }
  }
  .method {
    position: relative;
    display: flex;
    justify-content: center;
  }
  .pulldown {
    position: relative;
    display: flex;
    justify-content: center;
    cursor: pointer;

    button {
      background: none;
      padding: 4px 10px;
      margin: -5px -11px;
      border: solid 1px colors(gray);
      border-radius: 3px;
      width: 105px;
    }
  }
  .pulldownChanged {
    button {
      background: rgba(backgroundColors(info), 0.5);
    }
  }
  .paginator {
    margin: auto auto 60px auto;
    @media #{$smartphone} {
      margin: auto auto 40px auto;
    }
  }
  .errorMessage {
    width: 100%;
    color: colors(red);
    text-align: center;
  }
}

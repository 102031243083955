
@import "~jbc-front/styles/core";

:local {
  .sideContent {
    min-width: 342px;
    overflow-x: auto;
  }

  .tabContainer {
    display: flex;
    width: 100%;
    border-bottom: solid 1px borderColors(base, default);
  }

  .tab {
    color: textColors(sub, default);
    font-weight: bold;
    padding: 4px 10px;

    &:hover {
      cursor: pointer;
    }
  }

  .activeTab {
    color: colors(blue, default);
    font-weight: bold;
    padding: 4px 10px;
    border-bottom: solid 2px colors(blue, default);

    &:hover {
      cursor: default;
    }
  }

  .commentList {
    max-height: 85vh;
    overflow: auto;
  }

  .comment {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 6px;
    &.commentByAdmin {
      margin-left: 50px;
      .commentHeader {
        justify-content: flex-end;
      }
      .commentBody {
        background-color: colors(blue, default);
        color: #fff;
      }
    }
    &.commentByEmployees {
      margin-right: 50px;
      .commentBody {
        background-color: #f1f1f1;
      }
    }
  }

  .commentHeader {
    display: flex;
    flex-wrap: wrap;
    gap: 0 4px;
  }

  .icon {
    color: textColors(sub, default);
  }

  .commentDate {
    color: textColors(sub, default);
    font-size: 13px;
  }

  .commentBody {
    padding: 10px;
    border-radius: 6px;
    white-space: pre-wrap;
  }
}

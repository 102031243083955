:local {
  .body {
    border: solid 1px #ccc;
    border-radius: 3px;
    background: backgroundColors(head);
    padding: 8px 10px;
    margin-bottom: 20px;
  }
  .help {
    padding: 8px 10px;
    >ul>li:before {
      content: '※'
    }
  }
}

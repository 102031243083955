@import "~jbc-front/styles/core";
@import "frontend/src/pages/ClientSetting.scss";

:local {

  .plusSquare {
    margin:  0 1px 0 5px;
  }

  .boxDoubleWrapper {
    padding-top: 28px;
  }

  .reminderForm {
    width: 100%;
    padding-left: 30px;

    td {
      vertical-align: top;
      position: relative;
    }
  }

  .reminderText {
    top: 10px;
  }

  .reminderSelect {
    width: 110px;
    padding: 0 20px;
  }
}

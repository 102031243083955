@import "~jbc-front/styles/core";

:local {
  .centerStyles {
    display: flex;
    justify-content: center;
    align-items: center;

    @include dashed(borderColors(sub));
  }

  .button {
    composes: centerStyles;
    width: 100%;
    background-color: unset;
    border: unset;
    border-radius: 6px;
    padding: 30px 0px;
    cursor: pointer;
    font-size: 13px;
    color: textColors(base, default);
    background-color: backgroundColors(base);
  }

  .buttonContent {
    composes: centerStyles;
    max-width: 65%;
    max-height: 150px;
    width: 100%;
    height: 100%;
    padding: 20px;
    font-size: 20px;
    font-weight: bold;
    background-color: #fff;
    border-radius: 10px;
    color: #999;

    @media #{$smartphone} {
      max-width: 85%;
    }
  }

  .disabled {
    composes: button;
    cursor: not-allowed;
  }

  .icon {
    margin-right: 4px;
    color: #999;
    background-color: white;
  }
}

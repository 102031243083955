@import "~jbc-front/styles/core";

:local {
  .wrap {
    margin: 40px auto 0;
    max-width: 600px;
  }
  .head {
    padding: 8px 0;
    border-radius: 4px 4px 0 0;
    background-color: #a4a4a4;
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
  }
  .body {
    padding: 40px 45px;
    border: 1px solid #a4a4a4;
    border-radius: 0 0 4px 4px;
    @media #{$smartphone} {
      padding: 15px;
    }
  }

  .text {
    margin-bottom: 20px;
  }
  .large {
    min-width: 280px;
    display: inherit;
    margin: 30px 0 auto;
  }
}

@import "~jbc-front/styles/core";

:local {
  .grid {
    display: grid;
    grid-gap: 0px 20px;
    grid-auto-flow: row dense;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));

    @media #{$smartphone} {
      grid-template-columns: 1fr !important;
    }
  }

  .col2 {
    grid-template-columns: repeat(auto-fill, minmax(525px, 1fr));
  }

  .colGap {
    grid-gap: 20px;
  }
}

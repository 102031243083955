@import '~jbc-front/styles/core';

:local {
  .switch {
    position: relative;
    display: inline-block;
    composes: u-ta-l from global;
  }

  .balloon {
    position: absolute;
    top: 60px;
    z-index: 3000;
    background: white;
    padding: 10px;
    border: solid 1px colors(blue);
    word-break: keep-all;
    white-space: nowrap;
    &.center {
      left: 50%;
      transform: translate(-50%, 0);
    }
    &.right {
      left: 50%;
      transform: translate(-100%, 0) translate(50px, 0);
    }
    &:after {
      content: '';
      display: block;
      border-left: solid 1px colors(blue);
      border-top: solid 1px colors(blue);
      position: absolute;
      width: 20px;
      height: 20px;
      background: white;
      transform: rotate(45deg);
      top: -11px;
    }
    &.center:after {
      left: calc(50% - 11px);
    }
    &.right:after {
      right: 40px;
    }
  }
  .li {
    margin: 7px;
    font-size: 13px;
    svg {
      color: colors(gray);
      margin-right: 8px;
    }
  }
  .center {
    composes: balloon;
  }
  .right {
    composes: balloon;
  }
}

@import '~jbc-front/styles/core';

:local {
  .modal {
    border: 1px solid borderColors(base);
    border-radius: 4px;
    height: 90%;
    width: 95%;
    position: relative;
  }
  .overlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1200;
  }
  .header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid borderColors(base);
    padding: 15px;
    min-height: 60px;
    background-color: backgroundColors(base);
    color: #333;
  }
  .controller {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    padding: 10px 0;
    width: 254px;
    background-color: #fff;
    text-align: center;
  }
  .close {
    margin-left: 20px;
    width: 20px;
    height: 20px;
    color: #aaa;
    cursor: pointer;
  }
  .body {
    overflow: auto;
    height: calc(100% - 60px);
    background: #f1f1f1;
  }
  .pdf {
    width: 100%;
    height: 100%;
    padding: 20px 0 0;
    text-align: center;
  }
  .title {
    flex-basis: 50%;
    padding-top: 5px;
    font-size: 15px;
    font-weight: bold;
  }
  .tools {
    flex-basis: 50%;
    text-align: right;
  }
  .button {
    margin-left: 10px;
    box-shadow: none;
    padding: 0 12px;
    height: 28px;
    font-size: 13px;
    font-weight: bold;
    line-height: 28px;
    > div {
      right: -100px;
    }
  }
  .buttonEdit {
    composes: button;
  }
  .buttonIcon {
    margin-right: 5px;
  }

  .zoom {
    display: inline-block;
    margin: 0 7px;
    color: #333;
    cursor: pointer;
  }
  .scale {
    margin: 0 7px;
    font-weight: bold;
  }

  .page {
    cursor: pointer;

    svg {
      path {
        fill: #000;
      }
    }
  }
  .pagePrev {
    composes: page;
    margin: 0 7px 0 20px;

    svg {
      transform: rotate(180deg);
    }
  }
  .pageNext {
    composes: page;
    margin: 0 7px;
  }
  .pageNumber {
    margin: 0 7px;
    font-weight: bold;
  }
  .loading {
    margin: auto;
    height: 85%;
    display: inline-flex;
    align-items: center;
  }

  @media #{$smartphone} {
    .header {
      display: block;
      padding: 8px;
      height: auto;
    }
    .tools {
      display: flex;
      flex-wrap: wrap-reverse;
      justify-content: flex-start;
      align-items: center;
      margin-top: 10px;
      text-align: left;
      > div {
        margin-top: 10px;
      }
    }
    .button {
      margin-left: 0;
      margin-right: 5px;
      padding: 0 7px;
    }
    .close {
      margin-left: auto;
    }
    .body {
      height: calc(100% - 80px);
    }
  }
}

:local {
  .note {
    display: inline-block;
    position: relative;
  }
  .shape {
    position: absolute;
    bottom: 30px;
    left: 0;
    width: 15px;
    height: 15px;
    background: #666;
    transform: rotate(45deg);
  }
  .message {
    background: #666;
    border-radius: 3px;
    bottom: 37px;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    left: -110px;
    position: absolute;
    padding: 10px;
    text-align: left;
    word-break: keep-all;
    a {
      color: #fff;
      text-decoration: underline;
    }
  }
}

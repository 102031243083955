@import '~jbc-front/styles/core';

:local {
  .container {
    max-width: 655px;
    margin: 0 auto;
  }
  .employeeInfo {
    margin: 10px 0 0 0;
    > p {
      font-size: 16px;
      text-align: center;
    }
  }
  .formErrorsWrap {
    > div {
      > section {
        max-width: 100%;
      }
    }
  }
  .sectionWrap {
    > div,
    section {
      &:not(:first-child) {
        margin: 40px 0 0 0;
      }
    }
  }
  .tableLayout {
    $height: 50px;
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    th,
    td {
      font-weight: normal;
      height: $height;
    }
    .th {
      padding: 0 24px;
      text-align: left;
    }
    thead {
      tr {
        background-color: backgroundColors(base);
        th {
          &:not(:first-child) {
            border-left: 1px solid borderColors(table);
          }
        }
      }
    }
    tbody {
      tr {
        &:last-child {
          th,
          td {
            border-radius: 4px;
          }
        }
        th {
          background-color: backgroundColors(base);
          border-top: 1px solid borderColors(table);
        }
        td {
          border-top: 1px solid borderColors(table);
          &:not(:first-child) {
            border-left: 1px solid borderColors(table);
          }
        }
      }
    }
  }
  .noHeader {
    table > tbody {
      tr {
        &:first-child {
          th,
          td {
            border-top: none;
          }
        }
      }
    }
  }
  .bgGray {
    background-color: backgroundColors(base);
  }
  .bold {
    * {
      font-weight: bold !important;
    }
  }
  .panel {
    background: #ffffff;
    border: 1px solid colors(gray);
    border-radius: 4px;
  }
  .panelTitle {
    background-color: colors(gray);
    color: #ffffff;
    font-weight: bold;
    padding: 13px;
    text-align: center;
  }
  .panelPadding {
    padding: 18px 32px;
  }
  .inputNumber {
    border: 1px solid borderColors(sub);
    border-radius: 3px;
    box-shadow: none;
    font-size: 13px;
    max-width: 115px;
    padding: 6px 10px;
    text-align: right;
    &:read-only {
      border: none;
      background-color: transparent;
      outline: none;
      cursor: auto;
    }
  }
  .yen {
    font-size: 12px;
    margin: 0 0 0 8px;
  }
  .field {
    border: 2px solid transparent;
    padding: 9px 16px;
  }
  .totalField {
    @extend .field;
    display: flex;
    justify-content: flex-end;
  }
  .total {
    font-size: 13px;
    white-space: nowrap;
    overflow-x: auto;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .error {
    border: 2px solid colors(red);
  }
  .note {
    font-size: 12px;
    margin: 10px 0 0 0;
  }
  .icon {
    margin: 0 16px 0 0;
  }
  .button {
    margin: 0 0 16px 0;
    text-align: right;
    > div {
      min-width: 250px;
    }
  }

  @media #{$smartphone} {
    .panel {
      overflow-x: scroll;
    }
    .panelTitle,
    .panelPadding,
    .tableLayout {
      width: 665px;
    }
  }
}

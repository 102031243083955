@import "~jbc-front/styles/core";

:local {
  .body {
    border: solid 1px #ddd;
    border-radius: 4px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@import "~jbc-front/styles/core";

:local {
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .buttonContainer {
    &:first-child > .toggleButton {
      border-right: none;
      border-radius: 4px 0px 0px 4px;
    }

    &:last-child > .toggleButton {
      border-radius: 0px 4px 4px 0px;
    }
  }

  .toggleButton {
    border: 1px solid #aaa;
    color: #aaa;
    padding: 10px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    box-sizing: border-box;
  }

  .radio {
    display: none;

    &:checked + .toggleButton {
      background-color: #3498db;
      color: white
    }
  }
}

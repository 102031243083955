.c-dropdown {
  .dd-menu {
    .dd-menu-items {
      position: relative;
      margin: 0;
      left: 15px;
      top: 5px;
      ul {
        position: absolute;
        top: 0;
        right: 0;
        width: 250px;
        padding: 10px;
        background-color: #ffffff;
        box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.3);
        border-radius: 4px;
        color: #333;
        font-size: 14px;
        text-align: left;
        z-index: 100;
      }
      li {
        padding: 15px;
        &.clickable:hover {
          background: #ededed;
          font-weight: bold;
          cursor: pointer;
        }
      }
    }
  }
}


@import "~jbc-front/styles/core";

:local {
  .tag {
    background-color: colors(blue);
    border-radius: 3px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    padding: 5px;
  }
}

@import '~jbc-front/styles/core';

:local {
  .hint {
    position: relative;
  }
  .hintShape {
    position: absolute;
    top: -30px;
    left: 7px;
    width: 15px;
    height: 15px;
    background: #666;
    transform: rotate(45deg);
  }
  .hintMessage {
    position: absolute;
    left: -42px;
    bottom: 20px;
    padding: 3px;
    width: 112px;
    min-height: 40px;
    background: #666;
    color: #fff;
    font-size: 12px;
    text-align: center;
    white-space: pre-line;
  }
  .employeesTotalCount {
    text-align: center;
    @media #{$smartphone} {
      text-align: right;
    }
  }
}

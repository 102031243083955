@import "~jbc-front/styles/core";

:local {
  .toggles {
    display: flex;
    justify-content: center;
    margin: 10px 0 10px;
    border-radius: 4px;

    div {
      &:first-child {
        border-radius: 4px 0 0 4px;
      }
      &:last-child {
        border-radius: 0 4px 4px 0;
      }
    }

    @media #{$smartphone} {
      display: flex;
    }
  }
}
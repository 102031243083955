@import "~jbc-front/styles/core";
:local {
  .itemWrap {
    display: flex;
    flex-wrap: wrap;
  }
  .itemName {
    border-bottom: 1px solid borderColors(base);
    padding: 12px 0 10px 10px;
    width: 25%;
    font-weight: normal;
  }
  .itemValue {
    border-bottom: 1px solid borderColors(base);
    padding: 12px 10px 10px 0;
    width: 75%;
  }
}

@import "~jbc-front/styles/core";

:local {
  .layout {
    padding: 30px;
    gap: 30px;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    width: 100%;

    @media #{$smartphone} {
      flex-direction: column-reverse;
      padding: 0;
    }
  }

  .sideContent {
    position: sticky;
    top: 20px;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    gap: 30px;
    min-width: 300px;
    width: 300px;
    z-index: 2;

    @media #{$smartphone} {
      position: relative;
      top: 0;
      width: 100%;
      min-width: 100%;
      margin-top: 30px;
    }
  }

  .mainContent {
    width: 100%;
    max-width: calc(100% - 300px);
    display: flex;
    flex-direction: column;
    gap: 30px;

    @media #{$smartphone} {
      max-width: 100%;
    }
  }
}

@import "~jbc-front/styles/core";

:local {

  .toggleDiv {
    margin-bottom: 30px;
    background-color: #fafafa;
    border: 1px solid #ddd;
    border-radius: 4px;
    &Header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px;
    }
    &Body {
      padding: 15px;
      img {
        width: 100%;
        + img {
          margin-top: 30px;
        }
      }
    }
  }
  .expandIcon {
    margin-left: 4px;
  }
  .mainFormControl {
    min-width: 90px;
    margin-left: 10px;
  }

}

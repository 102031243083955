@import "~jbc-front/styles/core";

:local {
  .label {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 13px;

    span {
      min-width: 25px;
    }
  }
}


@import "~jbc-front/styles/core";

:local {
  .navigator {
    background-color: white;
    border-radius: 5px;
    border: solid 1px borderColors(base, default);
    overflow: hidden;
    width: 100%;
  }

  .header {
    background-color: backgroundColors(base);
    font-size: 14px;
    padding: 12px 16px;
    width: 100%;

    & + .body {
      border-top: solid 1px borderColors(base, default);
    }
  }

  .item {
    border-bottom: solid 1px borderColors(base, default);
    padding: 12px 16px;

    &:last-child {
      border-bottom: unset;
    }
  }
}

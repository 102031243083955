@import "~jbc-front/styles/core";

:local {
  .fileInput {
    color: red;
  }

  .fileInput + .fileContainer {
    margin-top: 20px;
  }
}

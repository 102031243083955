@import '~jbc-front/styles/core';

:local {
  $tableSubHeaderBackground: #e7e7e7;
  $tableThBorder: 1px solid #e0e0e0;
  $tableWidth: 1290px;
  .linkButtonWrap {
    margin: 0 auto 22px auto;
    max-width: $tableWidth;
  }
  .legalRecordsTableWrap {
    overflow: auto;
  }
  .legalRecordsTable {
    font-size: 12px;
    margin: 0 auto;
    width: $tableWidth;
    tr {
      th,
      td {
        &:not(:last-child) {
          border-right: $tableThBorder;
        }
      }
    }
    tbody {
      tr {
        td {
          &:not(:first-child) {
            padding: 10px 9px;
          }
          &:first-child {
            text-align: left;
          }
        }
      }
    }
  }

  .loadingLegalRecordsTable {
    font-size: 12px;
    margin: 0 auto;
    width: $tableWidth;
    height: 386px;
    display: flex;
    align-items: center;
    justify-items: center;
    tr {
      th,
      td {
        &:not(:last-child) {
          border-right: $tableThBorder;
        }
      }
    }
    tbody {
      tr {
        td {
          &:not(:first-child) {
            padding: 10px 9px;
          }
          &:first-child {
            text-align: left;
          }
        }
      }
    }
  }
  .tableWidth {
    width: $tableWidth;
    margin: 0 auto;
  }

  .aggregatedAtText {
    color: #444
  }

  .loadingMessage {
    font-size: 12px;
    margin: 0 auto;
    text-align: center;
  }
  .tableHeader {
    > th {
      background: #aaaaaa;
      color: #ffffff;
      font-size: 14px;
    }
  }
  .tableSubHeader {
    background: $tableSubHeaderBackground;
    > th {
      background: $tableSubHeaderBackground;
    }
  }
  .col2Tr {
    td {
      border-radius: 0 !important;
      &:first-child {
        border-left: none !important;
      }
    }
  }
  .tableRightBottom {
    text-align: right;
    vertical-align: bottom;
  }
  .tableLeftTop {
    text-align: left;
    vertical-align: top;
  }
  .inputBoxWrap {
    display: flex;
  }
  .numberBoxWrap {
    display: flex;
    flex-grow: 1;
    font-weight: bold;
    .numberBox {
      &:first-child {
        flex-grow: 2;
        letter-spacing: 5px;
      }
      border: 1px solid #eaeaea;
      border-radius: 2px;
      margin: 0 2px 0 0;
      min-width: 23px;
      padding: 7px;
      text-align: right;
    }
    .numberBoxComma {
      align-items: flex-end;
      display: flex;
      font-weight: normal;
      padding: 1px;
    }
  }
  .tableNumber {
    font-weight: bold;
    letter-spacing: 5px;
  }
  .suffix {
    align-items: flex-end;
    display: flex;
    font-size: 13px;
  }
  .procedureCard {
    background: #ffffff;
    box-shadow: 0 1px 0 0 #e0e0e0;
    font-size: 13px;
    margin: 53px 0 0 0;
    padding: 40px 75px;
    dd {
      margin: 6px 0 0 36px;
      &:not(:last-child) {
        margin-bottom: 35px;
      }
    }
  }
  .cardTitle {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    margin: 0 0 30px 0;
  }
  .definitionTerm {
    font-size: 14px;
    font-weight: normal;
  }
  .notExistsDescription {
    margin: 0 0 35px 0;
  }
  .emphasizeNumberWrap {
    align-items: center;
    display: flex;
  }
  .emphasizeNumber {
    align-items: center;
    background-color: #9f9f9f;
    border-radius: 2px;
    display: flex;
    height: 24.7px;
    justify-content: center;
    margin: 0 12px 0 0;
    width: 24px;
    > p {
      color: #ffffff;
      font-family: YuGo;
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
      letter-spacing: normal;
    }
  }
  .invalidCell {
    background: url(../../images/table-invalid.svg) no-repeat;
  }

  @media #{$smartphone} {
    .contentsLarge {
      width: 100%;
    }
  }
}

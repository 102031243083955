@import "~jbc-front/styles/core";

:local {
  .container {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 400px;
    min-width: 70px;
    height: 100vh;
    display: flex;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    right: 0;
    overflow: hidden;
    z-index: 999;

    @media #{$smartphone} {
      max-width: 100%;
    }
  }

  .resizer {
    height: 100%;
    width: 15px;

    &:hover {
      cursor: col-resize;
      background-color: backgroundColors(hover);
    }

    .resizerIcon {
      position: absolute;
      height: 100%;
      display: flex;
      align-items: center;
      color: #999;
    }
  }

  .header {
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    padding: 15px 20px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  }

  .content {
    padding: 10px;
    margin-top: 50px;
    width: calc(100% - 20px);
    overflow-y: auto;
  }

  .closeIcon {
    z-index: 1;
    color: #aaa;

    &:hover {
      cursor: pointer;
    }
  }
}

.containerAnimation-enter {
  transform: translateX(100%);
}

.containerAnimation-enter-active {
  transform: translateX(0%);
  transition: transform 300ms ease;
}

.containerAnimation-exit {
  transform: translateX(0%);
}

.containerAnimation-exit-active {
  transform: translateX(100%);
  transition: transform 300ms ease;
}

@import "~jbc-front/styles/core";

:local {

  .field {
    composes: radio from 'jbc-front/components/Form.scss';
  }
  .radio {
    margin-top: 30px;
  }
  .label {
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  .note {
    font-size: 13px;
    color: #777777;
  }
  .invalidField {
    composes: invalid from 'jbc-front/components/Form.scss';
    composes: field;
  }
  .text {
    max-width: 600px;
    margin-bottom: 10px;
    .important {
      color: colors(red);
    }
  }
  .description {
    margin-left: 138px;
  }
}

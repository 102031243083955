@import '~jbc-front/styles/core';

:local {
  .yeaContentsWrap {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
  }
  .head {
    padding: 35px 0 30px;
    background-color: backgroundColors(info);

    @media #{$smartphone} {
      margin: 0 -15px;
      padding: 20px 15px;
    }
  }
  .headTitle {
    color: #000;
    font-size: 24px;
    font-weight: normal;
    text-align: center;

    @media #{$smartphone} {
      font-size: 20px;
    }
  }

  .taskWrap {
    margin: 30px auto 0;
    width: 700px;

    @media #{$smartphone} {
      width: 100%;
    }
  }

  .taskList {
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 8px;
      color: #666;
      font-size: 16px;

      &:first-child {
        margin-top: 0;
      }

      div {
        margin-left: 15px;
      }
    }

    @media #{$smartphone} {
      li {
        font-size: 13px;
      }
    }
  }
  .check {
    margin-right: 10px;
  }

  .progress {
    margin: 25px auto 0;
    width: 580px;

    @media #{$smartphone} {
      width: 100%;
    }
  }
  .progressCount {
    margin-top: 10px;
    text-align: center;
  }
  .progressInfo {
    margin-right: 20px;
    font-size: 13px;
  }
  .progressDate {
    font-size: 15px;
    font-weight: 500;

    svg {
      position: relative;
      top: -1px;
      margin-right: 5px;
    }
  }

  .actions {
    position: relative;
    display: grid;
    grid-template-rows: 40px 1fr;
    grid-template-columns: 410px 1fr 195px;
    grid-column-gap: 6px;
    grid-row-gap: 20px;
    margin-bottom: 20px;

    @media #{$smartphone} {
      grid-template-rows: auto;
      grid-template-columns: auto;
      margin-top: 30px;
    }
  }
  .actionsDataDownload {
    grid-row: 1 / 2;
    grid-column: 1 / 2;

    @media #{$smartphone} {
      grid-row: 1 / 2;
      grid-column: 1 / 4;
      text-align: center;
      > div {
        margin-bottom: 10px;
      }
    }
  }
  .actionsList {
    grid-row: 1 / 2;
    grid-column: 2 / 3;
    text-align: right;

    @media #{$smartphone} {
      grid-row: 2 / 3;
      grid-column: 2 / 3;
    }
  }
  .actionData {
    grid-row: 1 / 2;
    grid-column: 3 / 4;

    @media #{$smartphone} {
      grid-row: 2 / 3;
      grid-column: 3 / 4;
    }
  }
  .actionsSearchForm {
    grid-row: 2 / 3;
    grid-column: 1 / 4;

    @media #{$smartphone} {
      grid-row: 3 / 4;
      grid-column: 1 / 4;
    }
  }
  .actionsConfirmIcon {
    position: absolute;
    left: 0;
    bottom: 0;
    grid-row: 2 / 3;
    grid-column: 1 / 2;

    @media #{$smartphone} {
      grid-row: 2 / 3;
      grid-column: 1 / 2;
    }
  }
  .confirmIcon {
    display: inline-flex;
    align-items: center;
    border-radius: 3px;
    padding: 0 10px;
    background-color: #edf7f4;
    border: 1px solid #17b491;
    height: 40px;
    color: #17b491;
    font-size: 20px;
    font-weight: 700;

    @media #{$smartphone} {
      font-size: 18px;
    }
  }
  .fixedAtLabel {
    margin-left: 10px;
    color: #17b491;
    font-weight: bold;
    vertical-align: 0.1rem;
  }

  .staffcodeColumn {
    composes: staffcodeColumn from './Top.scss';
  }
  .nameColumn {
    composes: nameColumn from './Top.scss';
  }
  .employmentStatusColumn {
    composes: employmentStatusColumn from './Top.scss';
  }
  .positionColumn {
    composes: positionColumn from './Top.scss';
  }
  .noteColumn {
    composes: noteColumn from './Top.scss';
  }
  .recordsColumn {
    flex-basis: 215px;
    width: 55px;
  }
  .withholdingSlipColumn {
    flex-basis: 100px;
    width: 100px;
  }
  .withholdingSlipButton {
    min-width: 100%;
    height: 30px;
    line-height: 30px;
  }

  .table {
    composes: m-table-list from global;
    composes: m-table-fixed from global;

    td {
      padding: 10px 10px 5px !important;
    }
  }

  .disabled {
    color: colors(gray, disabled);
    cursor: not-allowed;

    g {
      fill: #fff;
    }
  }
  .active {
    color: colors(gray, active);
    cursor: pointer;

    g {
      fill: #fff;
    }

    &:hover {
      color: colors(blue);
    }
  }

  .memo {
    composes: u-cur-pointer from global;
    width: 30px;
    height: 30px;
    background-size: 30px 30px;
    background-repeat: no-repeat;
  }
  .notCompleted {
    color: colors(blue);
  }


  .soonTitle {
    font-size: 30px;
    font-weight: 500;
    text-align: center;
  }

  .soonWrap {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }

  .soonItem {
    margin: 0 10px;
    width: 300px;
  }
  .soonImg {
    img {
      width: 100%;
      height: auto;
    }
  }
  .soonLead {
    margin-top: 20px;
    font-size: 16px;
    font-weight: bold;
  }
  .soonText {
    margin-top: 20px;
    color: textColors(sub, hover);
  }
  .soonInfo {
    margin-top: 40px;
    font-size: 18px;
    text-align: center;
  }
  .downloadReport {
    border-radius: 3px;
    border: solid 1px borderColors(button);
    padding: 10px;
    display: flex;
  }
  .bulkModalFormLabel {
    margin: 0 0 16px 0;
    font-weight: bold;
    font-size: 13px;
  }
  .bulkModalLabel {
    font-weight: bold;
    margin: 40px 0 16px 0;

    &:first-child {
      margin-top: 0;
    }
  }
  .loading {
    composes: sk-circle from global;
    margin: 0 0 0 5px;
    display: inline-block;
    width: 20px;
    height: 20px;
  }
  .bulkButton {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .paginator {
    margin-bottom: 60px;
    @media #{$smartphone} {
      margin: auto auto 40px auto;
    }
  }
  @media #{$smartphone} {
    .resultMemo {
      left: 0;
    }
  }

  .fixModalLabel {
    font-weight: bold;
  }
}

.checked_on {
  position: relative;
  padding-left: 35px;
  text-decoration: line-through;

  input {
    display: none;
  }

  &:after {
    position: absolute;
    content: '';
    top: 50%;
    left: 0;
    margin: -13px 0 0 0;
    width: 25px;
    height: 25px;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 25px 25px;
    background-color: transparent;
    background-image: url(~jbc-front/images/input/icon-checkbox-on.svg);
  }

  @media #{$smartphone} {
    padding-left: 30px;

    &:after {
      margin: -10px 0 0 0;
      width: 20px;
      height: 20px;
      background-size: 20px 20px;
    }
  }
}
.checked_off {
  position: relative;
  padding-left: 35px;

  input {
    display: none;
  }

  &:after {
    position: absolute;
    content: '';
    top: 50%;
    left: 0;
    margin: -12px 0 0 0;
    width: 25px;
    height: 25px;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 25px 25px;
    background-color: transparent;
    background-image: url(~jbc-front/images/input/icon-checkbox-off.svg);
  }

  @media #{$smartphone} {
    padding-left: 25px;

    &:after {
      margin: -10px 0 0 0;
      width: 20px;
      height: 20px;
      background-size: 20px 20px;
    }
  }
}

@import "~jbc-front/styles/core";

:local {
  .goToTopBtn {
    padding: 10px;
    position: fixed;
    bottom: 80px;
    right: -67px;
    background-color: $baseColor;
    color: #fff;
    border-radius: 4px 0 0 4px;
    cursor: pointer;
    transition: all ease 0.3s;
    @media #{$pc} {
      &:hover {
        right: 0;
      }
    }
    @media #{$smartphone} {
      bottom: 15px;
    }
  }
  .goToTopIcon {
    margin-right: 10px;
    transform: rotate(180deg);
  }
}

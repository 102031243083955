@import "~jbc-front/styles/core";

:local {
  .content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;

    @media #{$smartphone} {
      padding: 0;
    }
  }
}

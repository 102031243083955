@import "~jbc-front/styles/core";

:local {
  .formSection {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media #{$smartphone} {
      gap: 25px;
    }
  }
}

@import "~jbc-front/styles/core";

:local {
  .reportsList {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    gap: 1px;
    border: 1px solid #ddd;
    padding: 0;
    background-color: #ddd;
    border-radius: 4px;
    overflow: hidden;

    li {
      display: flex;
      flex-direction: column;
      min-width: 58px;
      padding: 10px 0;
      background-color: #fff;
      align-items: center;
      justify-content: center;
      text-align: center;
      cursor: pointer;

      .reportsIcon {
        display: block;
        margin: auto;
        margin-bottom: 2px;
      }
      &:hover {
        background-color: #ededed;
      }
    }
  }
}

@import '~jbc-front/styles/core';

:local {
  .warning {
    color: colors(red);
  }
  .disabledAnchor {
    > svg {
      color: colors(gray, disabled);
    }
    color: colors(gray, disabled);
    pointer-events: none;
  }
  .triangle {
    border: 6px solid transparent;
    border-top: 6px solid;
    border-top-color: inherit;
    border-radius: 2px;
    height: 0;
    position: relative;
    top: 10px;
    width: 0;
  }
  .importOptions:not(:first-child) {
    margin-top: 30px;
  }
  .list {
    margin-left: 20px;
    margin-top: 5px;
  }
  .attention {
    color: colors(red);
  }
}

@import "~jbc-front/styles/core";

:local {
  .caption {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .progress {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
}

@import "~jbc-front/styles/core";

:local {
  .wizardProvider {
    max-width: 1030px;
    width: 100%;

    > div:first-child {
        margin-bottom: 30px;
    }
  }
}

@import '~jbc-front/styles/core';

:local {
  .container {
    width: 600px;
    @media #{$smartphone} {
      width: 100%;
    }

  }
  .searchWrap {
    display: flex;
    justify-content: space-between;
    @media #{$smartphone} {
      flex-wrap: wrap;
    }
  }
  .inputSearch {
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 3px;
    display: flex;
    width: 340px;
    padding: 8px 8px 8px 0;
    > svg {
      width: 10%;
      color: #aaa;
    }
    > input {
      border: none;
      box-shadow: none;
      color: #333;
      font-size: 14px;
      width: 90%;
      outline: none;
    }
  }
  .backButton {
    border: 1px solid #ccc;
    @media #{$smartphone} {
      margin-left: auto;
      margin-top: 10px;
    }
  }
  .results {
    li {
      border-bottom: 1px solid #e0e0e0;
      padding: 15px 10px;
      cursor: pointer;
      &:hover {
        background: rgba(0, 0, 0, 0.07);
      }
    }
  }
  .pager {
    padding: 20px 0;
  }
}
